import React from 'react';
import {Card} from '@mui/material';
import {ISocialMediaItemProps} from "./Post";
import formatDate from "../../../shared/_utils/_helpers/formatDate";
import getSentimentInfo from "../../../shared/_utils/_helpers/Analysis";
import {IItem} from "../../../shared/types/IApiResponse";
import Pagination from "../../../components/pagination";

const SocialMediaItem: React.FC<{
    handlePageSizeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    posts: IItem[];
    currentPage: number;
    pageSize: number;
    totalMentions: number;
    onPageChange: (newPage: number) => void;
}> = ({handlePageSizeChange, posts, currentPage, pageSize, totalMentions, onPageChange}) => {

    console.log({totalMentions})
    console.log({pageSize})
    return (
        <div id="" className="">

            {/*<br/>*/}
            {/*{posts.length > 0 && (*/}
            {/*    <div className="row">*/}
            {/*        <div className="col-sm-12 col-md-6">*/}
            {/*            <label className="d-inline-flex">*/}
            {/*                Show*/}
            {/*                <select*/}
            {/*                    value={pageSize}*/}
            {/*                    onChange={handlePageSizeChange}*/}
            {/*                    name="datatables-orders_length"*/}
            {/*                    aria-controls="datatables-orders"*/}
            {/*                    className="form-select form-select-sm"*/}
            {/*                >*/}
            {/*                    <option value="2">2</option>*/}
            {/*                    <option value="5">5</option>*/}
            {/*                    <option value="10">10</option>*/}
            {/*                    <option value="25">25</option>*/}
            {/*                    <option value="50">50</option>*/}
            {/*                    <option value="100">100</option>*/}
            {/*                </select>*/}
            {/*                entries*/}
            {/*            </label>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}

            {posts.length === 0 ? (
                <div className="col-12">
                    <p className="text-center">Aucun post disponible</p>
                </div>
            ) : (
                posts.map(post => {
                    const {label, color} = getSentimentInfo(post.emotions[0]);

                    return (
                        <Card key={post.id} sx={{my: 2, mb: 0, borderRadius: 2, boxShadow: 1}}>
                            <div className="card" style={{marginBottom: 0}}>
                                <div className="card-header border-0 pb-0">
                                    <div className="d-flex align-items-start">
                                        <div className="stat text-primary">
                                            {post.author_influence?.name?.charAt(0).toUpperCase()}
                                        </div>
                                        <div className="flex-grow-1">
                                            <strong>{post.author_influence?.name ?? ""}</strong><br/>
                                        </div>
                                        <span className={`float-end badge badge-${color}-light`}>
                        <i className="mdi mdi-arrow-bottom-right"></i> {label}
                      </span>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <p>{post.description}</p>
                                    <a className="text-muted" href={post.clickable_url} target="_blank"
                                       rel="noopener noreferrer">
                                        <strong>{post.source_url}</strong>
                                    </a>
                                    <ul className="nav nav-pills nav-pills-light nav-fill nav-stack small border-top border-bottom py-1 my-3">
                                        {formatDate(post.created_at) && (
                                            <li className="nav-item">
                          <span className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none"
                                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                 className="feather feather-calendar align-middle me-1">
                              <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                              <line x1="16" y1="2" x2="16" y2="6"></line>
                              <line x1="8" y1="2" x2="8" y2="6"></line>
                              <line x1="3" y1="10" x2="21" y2="10"></line>
                            </svg>
                              {`Publié le ${formatDate(post.created_at)}`}
                          </span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </Card>
                    );
                })
            )}

            <br/>

            {posts.length > 0 && (
                <div className="row">
                        <div className="col-sm-12 col-md-5">
                            <div className="dataTables_info" id="datatables-orders_info" role="status"
                                 aria-live="polite">
                                {`Showing ${Math.min((currentPage - 1) * pageSize + 1, totalMentions)} to ${Math.min(currentPage * pageSize, totalMentions)} of ${totalMentions} entries`}
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                            <Pagination
                                currentPage={currentPage}
                                totalItems={totalMentions}
                                pageSize={pageSize}
                                onPageChange={onPageChange}
                            />
                        </div>
                </div>
            )}
        </div>
    );
};

export default SocialMediaItem;
