const STOPWORDS = ['la', 'le', 'les', 'de', 'du', 'des', 'et', 'à', 'pour', 'en', 'avec', 'sur', 'dans', 'par', 'au', 'aux', 'd\''];

export const extractImportantWords = (text: string): string[] => {
    return text
        .toLowerCase()
        .split(' ')
        .filter(word => !STOPWORDS.includes(word))
        .filter(word => word.length > 2); // Filtre les mots très courts
};

// {
//     "languages": [
//     "fr",
//     "en"
// ],
//     "name": "JO Paris 2024",
//     "query": {
//     "query_string": "(JO AND 2024) OR (JO AND Paris)",
//         "type": "advanced"
// },
//     "sources": [
//     "twitter",
//     "web",
//     "blogs",
//     "facebook",
//     "web",
//     "tiktok"
// ]
// }