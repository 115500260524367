import {IAnalyse, ISentimentInfo} from "../../../containers/Project/models/projects.response";
import {ISocialMediaItemProps} from "../../../containers/Project/components/Post";
import {isEmpty} from "../isEmpty";
import {IItem} from "../../types/IApiResponse";

export const positif = { label: 'Positif', color: 'success' } as ISentimentInfo
export const negatif = { label: 'Négatif', color: 'danger' } as ISentimentInfo
export const neutre = { label: 'Neutre', color: 'warning' } as ISentimentInfo

const getSentimentInfo = (analyse: String): ISentimentInfo => {

    switch (analyse) {
        case "disgust":
            return negatif;
        case "anger":
            return negatif;
        case "joy":
            return positif;
        default:
            return neutre;
    }
};

export default getSentimentInfo;
