import React, {useEffect, useState} from 'react';
import {useProSidebar} from "react-pro-sidebar";
import {useLocation, useNavigate} from "react-router-dom";
import {useSidebarContext} from "../SidebarContext";
import {IAlert, IAlertResponse} from "../../containers/Project/models/projects.response";
import {accountService} from "../../shared/services";
import {projectRepository} from "../../containers/Project/repository/project.repository";
import toast from "react-hot-toast";
import "./SideBar.css"
import ProtectedComponent from "../ProtectedComponent";

const SideBar = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(true);

    const yimboLogo = process.env.PUBLIC_URL + "/assets/yimba.png";

    const [alerts, setAlerts] = useState<IAlertResponse[]>();

    useEffect(() => {
        const user = accountService.getAuthUser();
        if (!user) {
            return;
        }

        console.log({user})

        projectRepository.projectsList({
            userId: user.id ?? user._id
        })
            .then(({data, error}) => {
                if (null != data) {
                    // console.log({data})
                    setAlerts(data.items)
                } else {
                    if (error) {
                        toast.error(error)
                    }
                }
            })
    }, [])

    const toggleSidebar = () => {
        setIsExpanded(!isExpanded);
    }

    const goToHome = () => {
        navigate("/")
    }

    const goToNewProject = () => {
        navigate("/projects/add")
    }

    const goToProject = (id: string) => {
        return navigate(`/projects/${id}`, {state: {previousLocationPathname: location.pathname, newProject: false}})
    }

    const goToSettings = () => {
        navigate("/settings");
    };

    const goToDashboard = () => {
        navigate("/dashboard")
    }

    const goToUsers = () => {
        navigate("/users")
    }

    return (


        <nav id="sidebar" className="sidebar js-sidebar">
            <div className="sidebar-content js-simplebar" data-simplebar="init">
                <div className="simplebar-wrapper" style={{margin: "0px"}}>
                    <div className="simplebar-height-auto-observer-wrapper">
                        <div className="simplebar-height-auto-observer"></div>
                    </div>
                    <div className="simplebar-mask">
                        <div className="simplebar-offset" style={{right: "0px", bottom: "0px"}}>
                            <div className="simplebar-content-wrapper" tabIndex={0} role="region"
                                 aria-label="scrollable content" style={{height: "100%", overflow: "hidden scroll"}}>
                                <div className="simplebar-content" style={{padding: "0px"}}>
                                        <a className="sidebar-brand" onClick={goToHome}>
                                            <span className="sidebar-brand-text align-middle">YIMBA</span>
                                        </a>

                                        <ul className="sidebar-nav">

                                            <li className={`sidebar-item ${location.pathname === "/dashboard" ? 'active' : ''}`}>
                                                <a className="sidebar-link" onClick={goToDashboard}>
                                                    <i className="align-middle" data-feather="sliders"/> <span
                                                    className="align-middle">Dashboard</span>
                                                </a>
                                            </li>
                                            <ProtectedComponent permissionsRequired={['can-create-user']}>
                                                <li className={`sidebar-item ${location.pathname === "/users" ? 'active' : ''}`}>
                                                    <a className="sidebar-link" onClick={goToUsers}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                             stroke-linejoin="round" className="feather feather-user align-middle">
                                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                            <circle cx="12" cy="7" r="4"></circle>
                                                        </svg>
                                                        <span
                                                            className="align-middle">Utilisateurs</span>
                                                    </a>
                                                </li>
                                            </ProtectedComponent>
                                            <li className="sidebar-item">
                                                <a onClick={toggleSidebar} data-bs-target="#tags" data-bs-toggle="collapse"
                                                   className="sidebar-link"
                                                   aria-expanded="true">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                         stroke-linejoin="round" className="feather feather-list align-middle">
                                                        <line x1="8" y1="6" x2="21" y2="6"></line>
                                                        <line x1="8" y1="12" x2="21" y2="12"></line>
                                                        <line x1="8" y1="18" x2="21" y2="18"></line>
                                                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                                                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                                                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                                                    </svg>
                                                    <span className="align-middle">Alertes</span>
                                                </a>
                                                <ul id="tags" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">

                                                    <li className="">
                                                        <a className="sidebar-link" onClick={goToNewProject}>
                                                            <span className="sidebar-badge badge bg-primary">+</span>Créer une alerte
                                                        </a>
                                                    </li>
                                                    {alerts && alerts.slice(0, 5).map((project, index) =>
                                                        <li className={`sidebar-item ${location.pathname === `/projects/${project._id}` ? 'active' : ''}`}>
                                                            <a className="sidebar-link" onClick={() => goToProject(project._id)}>
                                                                {project.alert.name}
                                                            </a>
                                                        </li>)}

                                                    {alerts && alerts.length > 0 && <li className="sidebar-item">
                                                        <a className="sidebar-link" onClick={goToDashboard}>
                                                            Voir plus
                                                        </a>
                                                    </li>}

                                                </ul>
                                            </li>
                                        </ul>
                                    <ProtectedComponent permissionsRequired={['can-create-role']}>
                                        <div className="sidebar-cta">
                                            <div className="sidebar-cta-content">
                                                <div className="d-grid">
                                                    <a onClick={goToSettings} className="btn btn-primary">Settings</a>
                                                </div>
                                            </div>
                                        </div>
                                    </ProtectedComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default SideBar