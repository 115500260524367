const extractBody = (response: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(response, 'text/html');
    const body = doc.querySelector('body');

    if (body) {
        return body.innerHTML;
    } else {
        return '';
    }
}

export default  extractBody;