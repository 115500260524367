import {BsTwitter} from "react-icons/bs";
import {SiLinkedin} from "react-icons/si";
import {BsYoutube} from "react-icons/bs";
import {FaFacebookF} from "react-icons/fa";
import "./footer.css"
import React from "react";

const Footer = () => {
    const Logo = process.env.PUBLIC_URL + "/assets/image191.svg";
    return (
        <section style={{backgroundColor: "rgb(226, 235, 249)"}}>
            <div className="k-container k-px-5 k-py-5">
                <div className="k-footer-wrapper k-gx-5">
                    <div className="k-footer-section-one">
                        <div className="k-footer-logo-container">
                            <img src={Logo} alt=""/>
                        </div>
                        <div className="k-footer-icons">
                            <a className="k-btn k-btn-twitter k-btn-social k-mx-1"><BsTwitter/></a>
                            <a className="k-btn k-btn-twitter k-btn-social k-mx-1"><SiLinkedin/></a>
                            <a className="k-btn k-btn-youtube k-btn-social k-mx-1"><BsYoutube/></a>
                            <a className="k-btn k-btn-facebook k-btn-social k-mx-1"><FaFacebookF/></a>
                        </div>
                    </div>
                    <div className="k-footer-section-two">
                        <div className="k-footer-section-columns">
                            <span>+225 05 54 63 16 63</span>
                            <span>contact@yimba.org</span>
                        </div>
                        <div className="k-footer-section-columns">
                            <span>Terms & Conditions</span>
                            <span>Privacy Policy</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;
