import { httpService } from "./http.service";
import {ISearchRequest} from "../types/searchRequest";

const getRapport = async (
    wordToSearch: string,
) => {

  return httpService.Axios.get(`/rapport/${wordToSearch}`);
};

const getProject = async ({
                            projectId,
                            size = 100,
                            sort, page
                          }: { projectId: string, active?: boolean; sort?: string; page?: number; size?: number }) => {

  let url = `/alerts/${projectId}/mentions?`;

  if (sort) {
    url += `sort=${sort}&`;
  }
  if (page !== undefined) {
    url += `page=${page}&`;
  }

  if (size !== undefined) {
    url += `size=${size}&`;
  }

  url = url.slice(-1) === '&' || url.slice(-1) === '?' ? url.slice(0, -1) : url;

  return httpService.Axios.get(url);
};

const getSources = async () => {
  return httpService.Axios.get(`/sources`);
};

const getLanguages = async () => {
  return httpService.Axios.get(`/languages`);
};

const projectsList = async ({
    userId,
    size = 100,
    sort = "desc", page
}: { userId: string, active?: boolean; sort?: string; page?: number; size?: number }) => {

  let url = `/alerts?`;

  if (userId) {
    url += `user_id=${userId}&`;
  }
  if (sort) {
    url += `sort=${sort}&`;
  }
  if (page !== undefined) {
    url += `page=${page}&`;
  }

  if (size !== undefined) {
    url += `size=${size}&`;
  }

  url = url.slice(-1) === '&' || url.slice(-1) === '?' ? url.slice(0, -1) : url;

  return httpService.Axios.get(url);
};

const createProject = async (searchRequest: ISearchRequest) => {
  return httpService.Axios.post("/alerts", searchRequest);
};

export const projectService = {
  getLanguages,
  getSources,
  getRapport,
  getProject,
  projectsList,
  createProject,
};
