import * as React from 'react';
import {useState} from 'react';
// @ts-ignore
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import moment from "moment";
import KTextField from "../../../components/KTextField";
import {t} from "i18next";
import "./kdateRange.css"
import {IconButton, InputAdornment} from "@mui/material";
import {CloseIcon} from "../../../shared/_utils/constants/icon";
import {isEmpty} from "../../../shared/_utils/isEmpty";
import {DateRangePicker} from 'react-date-range';

export interface IFilterTransactionsProps {
    filterFn: (params?: IDateRangeProps) => any
}

export interface IDateRangeProps {
    endDate: string,
    startDate: string
}

export default function KDateRange({filterFn}: IFilterTransactionsProps) {

    let now = new Date();
    let startt = moment(
        new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );

    let ends = moment(startt)
        .add(1, "days")
        .subtract(1, "seconds");

    let [start, setStart] = useState(startt);
    let [end, setEnd] = useState(ends);
    let [dateRange, setDateRange] = useState<string>("");

    let ranges = {
        [t("today")]: [moment(start), moment(end)],
        [t("yesterday")]: [
            moment(start).subtract(1, "days"),
            moment(end).subtract(1, "days")
        ],
        [t("lastSevenDays")]: [moment(start).subtract(7, "days"), moment(end)],
        [t("thisMonth")]: [moment(start).startOf("month"), moment(end).endOf("month")],
        [t("lastMonth")]: [
            moment(start).subtract(1, "month").startOf("month"),
            moment(end).subtract(1, "month").endOf("month")
        ]
    };
    let local = {
        "format": "DD-MM-YYYY",
        "sundayFirst": false,
        smartMode: true,
        noMobileMode: true
    };
    let maxDate = moment(start).add(24, "hour");

    const applyCallback = (startDate: moment.Moment, endDate: moment.Moment) => {
        setStart(startDate);
        setEnd(endDate);
        const fullDate = `${startDate.format("DD-MM-YYYY")} - ${endDate.format("DD-MM-YYYY")}`
        setDateRange(fullDate)

        const dataRange = {} as IDateRangeProps;
        dataRange.startDate = startDate.format("YYYY-MM-DD");
        dataRange.endDate = endDate.format("YYYY-MM-DD");
        filterFn(dataRange)
    };

    const handleClickClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setDateRange("")
        filterFn()
    }

    const handleSelect = (ranges: any) => {
        console.log(ranges);
        // {
        //   selection: {
        //     startDate: [native Date Object],
        //     endDate: [native Date Object],
        //   }
        // }
    }

    const selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }

    const handleClearClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        handleClickClear(e); // Supprimez la plage de dates ici
    };

    return (
        <div className="customCalendarWrapper">
            <DateTimeRangeContainer
                ranges={ranges}
                start={start}
                end={end}
                local={local}
                applyCallback={applyCallback}
                noMobileMode="false"
                // leftMode="false"
                centerMode="false"
            >
                <form className="d-sm-inline-block">
                    <div className="input-group input-group-navbar">
                        <input type="text" className="form-control" placeholder="Filtrer ... " value={dateRange}
                               onChange={(e) => setDateRange(e.target.value)}
                               aria-label="Filtrer"/>
                        <button className="btn" type="button" onClick={handleClearClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" className="feather feather-x-circle align-middle me-2">
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="15" y1="9" x2="9" y2="15"></line>
                                <line x1="9" y1="9" x2="15" y2="15"></line>
                            </svg>
                        </button>
                    </div>
                </form>
            </DateTimeRangeContainer>
        </div>
    );
}