import {extractImportantWords} from './keywordExtractor';

export const buildQuery = (name: string) => {
    const importantWords = extractImportantWords(name);
    return createCombinations(importantWords);
};

const createCombinations = (words: string[]): string => {
    if (words.length < 2) {
        return words.join(' AND ');
    }

    const combinations: string[] = [];

    for (let i = 0; i < words.length - 1; i += 2) {
        if (i + 1 < words.length) {
            combinations.push(`(${words[i]} AND ${words[i + 1]})`);
        } else {
            // If there's an odd word out, just add it as is
            combinations.push(words[i]);
        }
    }

    return combinations.join(' OR ');
};
