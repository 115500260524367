import {AxiosError, AxiosResponse, isAxiosError} from "axios";
import {t} from "i18next";
import {IApiResponse} from "../../../types/IApiResponse";
// import toast from "react-hot-toast";
import React from "react";
import {Bounce, toast} from "react-toastify";

export function handleAPICommonResp(payload: any) {
    const {success, error, errorMessage, message, statusText} = payload;

    let displayMessage = errorMessage || statusText || message;

    if (!success || error) {
        if (displayMessage == null) {
            displayMessage = t("errorOccurred");
        }

        throw displayMessage;
    }
}

export function displayError(exception: any): IApiResponse<null> {
    const resp = {} as IApiResponse<null>;
    const notify = (msg: string) => toast.error(msg, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

    if (isAxiosError(exception)) {
        const axiosError = exception as AxiosError;
        console.log({axiosError})
        if (axiosError.response) {
            const axiosResponse: AxiosResponse = axiosError.response;

            console.log({axiosResponse})
            const {data, status, statusText} = axiosResponse;
            resp.error = data.errorMessage || data.message || data.message_error || data.statusText || axiosError.message;
            resp.statusCode = status
            resp.data = null
        } else {
            resp.data = null
            resp.error = axiosError.message;
        }
    } else {
        resp.data = null
        resp.error = exception.toString() ?? t("errorOccurred");
    }

    // @ts-ignore
    // toast.error(t(resp.error))
    notify(resp.error)

    return resp;
}
