import * as React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { withStyles } from "@mui/material";
import styled from "@emotion/styled";

export const inputLabelStyles = {
  color: "#003566",
  textTransform: "none",
};

export const rootStyles = {
  // backgroundColor: '#ffd60a',
  // border: 'none',
  // "&:focus-within fieldset, &:focus-visible fieldset": {
  //   // color: '#768192',
  //   // backgroundColor: '#fff',
  //   // borderColor: '#958bef',
  //   // outline: 0,
  //   boxShadow: "0 0 0 0.2rem rgb(50 31 219 / 25%)",
  //   border: "none",
  // },
  '& label.Mui-focused': {
    color: '#768192',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'rgb(50 31 219 / 25%)',
  },
  '& .MuiOutlinedInput-root': {
    // '& fieldset': {
    //   borderColor: 'red',
    // },
    // '&:hover fieldset': {
    //   borderColor: 'yellow',
    // },
    '&.Mui-focused fieldset': {
      borderColor: 'rgb(50 31 219 / 25%)',
    },
  },
};

export const rootInputStyles = {
  // '&:hover fieldset': {
  //     border: '2px solid red!important',
  //     borderRadius: 0,
  // },
  border: "none",
  "&:focus-within fieldset, &:focus-visible fieldset": {
    // color: '#768192',
    // backgroundColor: '#fff',
    // borderColor: '#958bef',
    // outline: 0,
    // boxShadow: '0 0 0 0.2rem rgb(50 31 219 / 25%)'
  },
};

export const inputStyles = {
  paddingLeft: "15px",
  display: "block",
  // width: '100%',
  height: "calc(1.5em + 2px)",
  padding: "0.375rem 0.75rem",
  fontSize: ".875rem",
  fontWeight: 400,
  // lineHeight: 1.5,
  backgroundClip: "padding-box",
  color: "#768192",
  backgroundColor: "#fff",
  // border: '1px solid #d8dbe0',
  borderRadius: "0.25rem",
  transition: "border-color .15s ease-in-out, box-shadow .15s ease-in-out",
  border: "none",
  "&:focus-within, &:focus-visible": {
    color: "#768192",
    backgroundColor: "#fff",
    borderColor: "#958bef",
    outline: 0,
    // boxShadow: '0 0 0 0.2rem rgb(50 31 219 / 25%)'
  },
};

const helperTextStyles = {
  color: "red",
};

// interface IKTextFieldProps extends TextFieldProps{
//     label?: string
//     validation?: Record<string, any>;
//     customRootStyles?: React.CSSProperties
//     customInputLabelStyles?: React.CSSProperties
//     customRootInputStyles?: React.CSSProperties
//     customInputStyles?: React.CSSProperties
//     customHelperTextStyles?: React.CSSProperties
// }

type IKTextFieldProps = Omit<TextFieldProps, "yourPropToExclude"> & {
  validation?: Record<string, any>;
  customRootStyles?: React.CSSProperties;
  customInputLabelStyles?: React.CSSProperties;
  customRootInputStyles?: React.CSSProperties;
  customInputStyles?: React.CSSProperties;
  customHelperTextStyles?: React.CSSProperties;
};

const KTextField = <T extends TextFieldProps>({
  label = "",
  validation,
  customRootStyles,
  customInputLabelStyles,
  customRootInputStyles,
  customInputStyles,
  customHelperTextStyles,
  ...props
}: IKTextFieldProps) => {
  return (
    <TextField
      label={label}
      size="small"
      sx={{
        ...rootStyles,
        ...customRootStyles }}
      InputLabelProps={{
        sx: {
          // ...inputLabelStyles,
          ...customInputLabelStyles,
        },
      }}
      InputProps={{
        sx: {
          // ...rootInputStyles,
          ...customRootInputStyles,
        },
      }}
      inputProps={{
        sx: {
          // ...inputStyles,
          ...customInputStyles,
        },
      }}
      {...props}
      {...validation}
    />
  );
};

export default KTextField;
