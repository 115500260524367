import axios, {AxiosError, AxiosInstance} from "axios";
import { accountService } from "./account.service";
import appVersion from "../../config/appVersion";
import i18next from "i18next";

const baseUrl = process.env.REACT_APP_API_URL;


const setupInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(async (request) => {
    request.headers["Accept-Language"] = i18next.language;
    // request.headers["version"] = appVersion;
    // request.headers["mode"] = "no-cors";
    request.headers["Accept"] = "application/json"

    if (accountService.isLogged()) {
      request.headers.Authorization = "Bearer " + accountService.getToken();
    }

    return request;
  });

  instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          accountService.logout();
          window.location.assign("login");
        } else {
          return Promise.reject(error);
        }
      }
  );
}

const createAxiosInstance = (baseURL?: string): AxiosInstance => {
  const instance = axios.create({
    baseURL: baseURL,
  });
  setupInterceptors(instance);
  return instance;
};

const Axios = createAxiosInstance(baseUrl);

const authBaseUrl = 'https://mention.yimba.net';
const customAxios = createAxiosInstance(authBaseUrl);

export const httpService = { Axios, createAxiosInstance, customAxios };