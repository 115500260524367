import React, {useEffect, useState} from 'react';
import {useLoaderData, useLocation, useNavigate, useParams} from "react-router-dom";
import {IAlert} from "../Project/models/projects.response";
import {accountService} from "../../shared/services";
import {projectRepository} from "../Project/repository/project.repository";
import toast from "react-hot-toast";
import formatDate from "../../shared/_utils/_helpers/formatDate";
import {userRepository} from "../Auth/repository/user.repository";
import {IUser, IUsersResponse} from "../../shared/types/users";
import Pagination from "../../components/pagination";

const Dashboard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>()

    const [users, setUsers] = useState<IUser[]>();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalUsers, setTotalUsers] = useState(0);

    const addProjectIllustration = process.env.PUBLIC_URL + "/assets/new-project.png";

    const goToNewProject = () => {
        navigate("/projects/add")
    }

    const goToProject = (id: string) => {
        return navigate(`/projects/${id}`, {state: {previousLocationPathname: location.pathname, newProject: false}})
    }

    useEffect(() => {

        setLoading(true)

        userRepository.getAllUsers(currentPage, pageSize)
            .then(({data}) => {
                if (null != data) {
                    setUsers(data.items)
                    setTotalUsers(data.total);
                    setTotalUsers(data.page);
                }
            })
            .finally(() => setLoading(false))
    }, [currentPage, pageSize])

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (event: { target: { value: string; }; }) => {
        setPageSize(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };

    const goToAddUser = () => {
        navigate("/users/create")
    }

    const editUserFn = (userId: String) => {
        navigate(`/users/${userId}`)
    }

    return (
        <div className="container-fluid p-0">
            <a onClick={goToAddUser} className="btn btn-primary float-end mt-n1"><i className="fas fa-plus"></i> Ajouter
                un utilisateur</a>
            <div className="mb-3">
                <h1 className="h3 d-inline align-middle">Utilisateurs</h1>

            </div>


            <div className="card">
                <div className="card-body">
                    <div id="datatables-orders_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="dataTables_length" id="datatables-orders_length"><label>Show
                                    <select value={pageSize} onChange={handlePageSizeChange}
                                            name="datatables-orders_length" aria-controls="datatables-orders"
                                    className="form-select form-select-sm">
                                    <option value="2">2</option>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                    entries
                                </label>
                                </div>
                            </div>
                        </div>
                        <div className="row dt-row">
                            <div className="col-sm-12">
                                <table id="datatables-users"
                                       className="table table-responsive table-striped dataTable no-footer dtr-inline"
                                       width="100%" aria-describedby="datatables-users_info" style={{width: "100%"}}>
                                    <thead>
                                    <tr>
                                        <th className="sorting" tabIndex={0} aria-controls="datatables-users"
                                            rowSpan={1} colSpan={1} aria-label="#: activate to sort column ascending">#
                                        </th>
                                        <th className="sorting" tabIndex={1} aria-controls="datatables-users"
                                            rowSpan={1} colSpan={1}
                                            aria-label="Fullname: activate to sort column ascending">Fullname
                                        </th>
                                        <th className="sorting" tabIndex={1} aria-controls="datatables-users"
                                            rowSpan={1} colSpan={1}
                                            aria-label="Email: activate to sort column ascending">Email
                                        </th>
                                        <th className="sorting" tabIndex={1} aria-controls="datatables-users"
                                            rowSpan={1} colSpan={1}
                                            aria-label="Date Created: activate to sort column ascending">Date Created
                                        </th>
                                        <th className="sorting" tabIndex={1} aria-controls="datatables-users"
                                            rowSpan={1} colSpan={1}
                                            aria-label="Role: activate to sort column ascending">Role
                                        </th>
                                        <th className="sorting" tabIndex={1} aria-controls="datatables-users"
                                            rowSpan={1} colSpan={1}
                                            aria-label="Status: activate to sort column ascending">Status
                                        </th>
                                        <th className="sorting_disabled" rowSpan={1} colSpan={1}
                                            aria-label="Actions">Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users && users.length > 0 ? (
                                        users.map((user, index) => (
                                            <tr key={user._id} className={index % 2 === 0 ? "even" : "odd"}>
                                                <td className="dtr-control" tabIndex={1}>
                                                    <strong>{`#${index + 1}`}</strong></td>
                                                <td className="sorting_1">{user.fullname}</td>
                                                <td>{user.email}</td>
                                                <td>{new Date(user.created_at).toLocaleDateString()}</td>
                                                <td>{user.extra.role_info.name}</td>
                                                <td>
                                                    <span
                                                        className={`badge ${user.is_active ? "badge-success-light" : "badge-danger-light"}`}>
                                                        {user.is_active ? "Active" : "Inactive"}
                                                    </span>
                                                </td>
                                                <td>
                                                    <a onClick={() => editUserFn(user._id)} href="#"
                                                       className="btn btn-primary btn-sm">Modifier</a>
                                                    {/*<a href="#" className="btn btn-primary btn-sm">Edit</a>*/}
                                                </td>
                                            </tr>))
                                    ) : (
                                        <tr>
                                            <td colSpan={7} style={{textAlign: "center"}}>
                                                No users found.
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-5">
                                <div className="dataTables_info" id="datatables-orders_info" role="status" aria-live="polite">
                                    {`Showing ${Math.min((currentPage - 1) * pageSize + 1, totalUsers)} to ${Math.min(currentPage * pageSize, totalUsers)} of ${totalUsers} entries`}
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-7">
                                <Pagination
                                    currentPage={currentPage}
                                    totalItems={totalUsers}
                                    pageSize={pageSize}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Dashboard;
