import React from 'react';

const Pagination = ({ currentPage, totalItems, pageSize, onPageChange }) => {
    const totalPages = Math.ceil(totalItems / pageSize);

    return (
        <div className="dataTables_paginate paging_simple_numbers" id="datatables-buttons_paginate">
            <ul className="pagination">
                <li className={`paginate_button page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <a onClick={() => onPageChange(currentPage - 1)} className="page-link">Previous</a>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                    <li key={index} className={`paginate_button page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                        <a onClick={() => onPageChange(index + 1)} className="page-link">{index + 1}</a>
                    </li>
                ))}
                <li className={`paginate_button page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <a onClick={() => onPageChange(currentPage + 1)} className="page-link">Next</a>
                </li>
            </ul>
        </div>
    );
};

export default Pagination;
