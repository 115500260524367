import React, {useEffect, useState} from 'react';
import "./k-index.css"
import {motion, useAnimation} from "framer-motion";
import {Link as Scroll} from "react-scroll/modules";
import {Collapse, IconButton} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useNavigate} from "react-router-dom";
import {useInView} from "react-intersection-observer";
import Footer from "./components/Footer";

const Home = () => {

    const [checked, setChecked] = useState(false);
    const navigate = useNavigate();

    const collectImg = process.env.PUBLIC_URL + "/assets/collecte-data.png";
    const analyseImg = process.env.PUBLIC_URL + "/assets/analyse.png";
    const notificationImg = process.env.PUBLIC_URL + "/assets/img-section-notification.png";

    const {ref: myRef, inView: isVisible} = useInView({
        threshold: 0,
    });

    const workInfoData = [
        {
            image: <svg xmlns="http://www.w3.org/2000/svg" height="64" width="64" viewBox="0 0 512 512">
                <path fill="#82c2db"
                      d="M448 256A192 192 0 1 0 64 256a192 192 0 1 0 384 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 80a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zM224 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
            </svg>,
            title: "Précision Émotionnelle",
            text: "Collecte précise pour une compréhension approfondie.",
        },
        {
            image: <svg xmlns="http://www.w3.org/2000/svg" height="64" width="64" viewBox="0 0 512 512">
                <path fill="#81c1da"
                      d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zm-312 8v64c0 13.3 10.7 24 24 24s24-10.7 24-24l0-64c0-13.3-10.7-24-24-24s-24 10.7-24 24zm80-96V280c0 13.3 10.7 24 24 24s24-10.7 24-24V120c0-13.3-10.7-24-24-24s-24 10.7-24 24zm80 64v96c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/>
            </svg>,
            title: "Analyse Intelligente",
            text: "Transformez les données en insights exploitables.",
        },
        {
            image: <svg xmlns="http://www.w3.org/2000/svg" height="64" width="64" viewBox="0 0 448 512">
                <path fill="#81c1da"
                      d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z"/>
            </svg>,
            title: "Surveillance en Temps Réel",
            text: "Restez toujours connecté aux dernières tendances",
        },
    ];

    const controls = useAnimation();

    const imgVariants = {
        hidden: {
            opacity: 0,
            x: 20,
        },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 1,
                delay: 0.5,
            },
        },
    };

    useEffect(() => {
        if (isVisible) {
            controls.start("visible");
        } else {
            controls.start("hidden");
        }
    }, [controls, isVisible]);

    const Logo = process.env.PUBLIC_URL + "/assets/image191.svg";

    useEffect(() => {
        setChecked(true);
    }, []);

    const goToLogin = () => {
        navigate("/login")
    }

    const goToRegister = () => {
        navigate("/sign-up")
    }

    return (
        <div>
            <nav className="k-navbar k-navbar-expand-lg k-navbar-light k-bg-white k-fixed-top">
                <div className="k-container k-px-5">
                    <a className="k-navbar-brand" href="#page-top"><img src={Logo} alt=""/></a>
                    <button className="k-navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                            aria-label="Toggle navigation"><span className="k-navbar-toggler-icon"></span></button>
                    <div className="k-collapse k-navbar-collapse" id="navbarResponsive">
                        <ul className="k-navbar-nav k-ms-auto">
                            <li className="k-nav-item"><a
                                className="k-btn k-btn-primary-revert k-btn-lg k-rounded-pill k-mt-3"
                                onClick={goToLogin}
                            >
                                Se connecter
                            </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <Collapse
                in={checked}
                {...(checked ? {timeout: 1000} : {})}
            >
                <header className="k-masthead k-text-center">
                    <div className="k-masthead-content">

                        <div className="k-container k-px-5">
                            <h1 className="k-masthead-heading k-mb-0">
                                Bienvenue sur <span className={"k-colorText"}>Yimba,</span> <br/>
                                Le pouls des opinions en ligne
                            </h1>
                            <h6 className="k-masthead-subheading k-mb-0">
                                Votre portail exclusif pour décoder les émotions et opinions de la société en ligne.
                                Avec une technologie de pointe, Yimba révolutionne la manière dont nous comprenons et
                                analysons
                                les commentaires en ligne, vous offrant des insights inestimables en temps réel.
                            </h6>
                            <div>
                                <motion.a
                                    initial={{scale: 1}}
                                    whileHover={{scale: 1.05}}
                                    className="k-btn k-btn-primary k-btn-xl k-rounded-pill k-mt-3"
                                    href="#scroll"
                                >
                                    En savoir plus
                                </motion.a>
                            </div>
                            <div>
                                <Scroll to="k-collect-data" smooth={true}>
                                    <motion.div
                                        animate={{y: [0, 20, 0]}}
                                        transition={{duration: 1, repeat: Infinity}}
                                    >
                                        <IconButton>
                                            <ExpandMoreIcon className={"k-goDown"}/>
                                        </IconButton>
                                    </motion.div>
                                </Scroll>
                            </div>
                        </div>

                    </div>
                </header>
            </Collapse>


            <section id="scroll" style={{
                backgroundColor: "rgb(226, 235, 249)",
            }}>
                <div className="k-container k-px-5">
                    <div className="k-row k-gx-5 k-align-items-center">
                        <div className="k-col-lg-6 k-order-lg-2">
                            <div className="k-p-5">
                                <motion.img
                                    // variants={imgVariants}
                                    initial={"hidden"}
                                    animate={controls}
                                    src={collectImg}
                                    className="k-img-fluid k-rounded-circle"
                                />
                            </div>
                        </div>
                        <motion.div animate={controls} initial="hidden"
                                    className="k-col-lg-6 k-order-lg-1">
                            <div className="k-p-5">
                                <h2 className="k-display-4">Explorez le Pouvoir de la Collecte Ciblée</h2>
                                <p className="k-h6">
                                    Notre plateforme est conçue pour collecter de manière précise les émotions et
                                    opinions à partir de diverses sources en ligne, vous offrant une vision complète des
                                    tendances émergentes.
                                </p>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>


            <section style={{
                background: "linear-gradient(88.18deg, rgba(172, 228, 248, 0.48) 7.61%, rgba(18, 137, 189, 0.48) 96.22%)",
                padding: "1rem 0"
            }}>
                <div className="k-container k-px-5">
                    <div className="k-row k-gx-5 k-align-items-center">
                        <div className="k-col-lg-6">
                            <div className="k-p-5"><img className="k-img-fluid k-rounded-circle" src={analyseImg}
                                                        alt="decryptage des resultats collectés"/></div>
                        </div>
                        <div className="k-col-lg-6">
                            <div className="k-p-5">
                                <h2 className="k-display-4">Décryptez les commentaires avec une analyse avancée</h2>
                                <p className="k-h6">Yimba excelle dans l'analyse avancée, transformant des commentaires apparemment
                                    anodins en informations exploitables. Plongez dans des insights pertinents qui vous
                                    guideront dans la compréhension profonde des sentiments dominants.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style={{
                backgroundColor: "rgb(226, 235, 249)",
            }}>
                <div className="k-container k-px-5">
                    <div className="k-row k-gx-5 k-align-items-center">
                        <div className="k-col-lg-6 k-order-lg-2">
                            <div className="k-p-5"><img className="k-img-fluid k-rounded-circle" src={notificationImg}
                                                        alt="..."/></div>
                        </div>
                        <div className="k-col-lg-6 k-order-lg-1">
                            <div className="k-p-5">
                                <h2 className="k-display-4">Restez Connecté avec la Surveillance Continue</h2>
                                <p className="k-h6">Avec Yimba, soyez toujours en avance sur les tendances émotionnelles. Notre fonction
                                    de surveillance continue, avec un focus particulier sur les médias sociaux, vous
                                    permet de rester informé en temps réel.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="k-container k-masthead-content k-px-4 k-px-lg-5 k-py-5 k-mt-lg-7 k-mt-5 k-mb-lg-7 k-mb-5">
                    <div className="k-masthead-content k-row k-gx-5 k-align-items-center"/>
                    <h2 className="k-text-center k-display-4">Pourquoi Yimba ?</h2>
                    <hr className="k-divider"/>
                    <div className="k-row k-gx-4 k-gx-lg-5">
                        {workInfoData && workInfoData.map((item, index) => <motion.div
                            className="k-col-lg-4 k-col-md-6 k-text-center"
                            initial={{scale: 1}}
                            whileHover={{scale: 1.1}}
                        >
                            <div className="k-mt-5" key={index}>
                                <div className="k-mb-2">{item.image}</div>
                                <h3 className="k-h4 k-mb-2">{item.title}</h3>
                                <p className="k-text-muted k-mb-0 k-h6">{item.text}</p>
                            </div>
                        </motion.div>)}
                    </div>
                </div>
            </section>

            <Footer/>
        </div>
    );
};

export default Home;
