import {IApiResponse} from "../types/IApiResponse";
import {accountService, permissionsService} from "../services";
import {displayError} from "../_utils/_helpers/error";
import {IPermissionsResponse} from "../types/permissions";
import {IRoleInfo, IUsersResponse} from "../types/users";
import {IRolesApiResponse} from "../types/roles";

const assignPermissions = async (
    roleId: string,
    permissionsId: []
): Promise<IApiResponse<IRoleInfo | null>> => {
    try {
        const { data, status, statusText } = await accountService.assignPermissions(roleId, permissionsId);
        console.log({data})
        return Promise.resolve({
            data: data as IRoleInfo,
            error: null,
        });
    } catch (error) {
        console.log({error})
        return displayError(error)
    }
};

const createRole = async (
    name: string,
    description: string
): Promise<IApiResponse<IRoleInfo | null>> => {
    try {
        const { data, status, statusText } = await accountService.createRole(name, description);
        console.log({data})
        return Promise.resolve({
            data: data as IRoleInfo,
            error: null,
        });
    } catch (error) {
        console.log({error})
        return displayError(error)
    }
};

const getRoles = async (): Promise<IApiResponse<IRolesApiResponse | null>> => {
    try {
        const { data, status, statusText } = await accountService.getRoles();

        return Promise.resolve({
            data: data as IRolesApiResponse,
            error: null,
        });
    } catch (error) {
        console.log({error})
        return displayError(error)
    }
};

const getPermissions = async (): Promise<IApiResponse<IPermissionsResponse | null>> => {
    try {
        const { data, status, statusText } = await permissionsService.getPermissions();

        return Promise.resolve({
            data: data as IPermissionsResponse,
            error: null,
        });
    } catch (error) {
        console.log({error})
        return displayError(error)
    }
};


export const permissionRepository = {
    assignPermissions,
    createRole,
    getRoles,
    getPermissions
};
