import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row text-muted">
                    <div className="col-6 text-start">
                        <p className="mb-0">
                            <a className="text-muted" href="https://yimba.net"
                               target="_blank"><strong>Yimba </strong></a>
                            - <a className="text-muted" href="https://yimba.net" target="_blank"><strong>Le pouls des opinions en ligne</strong></a> &copy;
                        </p>
                    </div>
                    <div className="col-6 text-end">
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <a className="text-muted" href="https://yimba.net" target="_blank">Privacy</a>
                            </li>
                            <li className="list-inline-item">
                                <a className="text-muted" href="https://yimba.net" target="_blank">Terms</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer