import {httpService} from "./http.service";
import jwt_decode from "jwt-decode";
import {IUser} from "../../containers/Auth/models/auth.response";

const getRoles = async (sorting?: string, page?: number, size?: number) => {
    let url = '/roles?';

    if (sorting) {
        url += `sort=${sorting}&`;
    }
    if (page !== undefined) {
        url += `page=${page}&`;
    }
    if (size !== undefined) {
        url += `size=${size}&`;
    }

    url = url.slice(-1) === '&' || url.slice(-1) === '?' ? url.slice(0, -1) : url;

    return httpService.customAxios.get(url);
};

const getAllUsers = async ({ active, sort, page, size }: { active?: boolean; sort?: string; page?: number; size?: number }) => {
    let url = '/users?';

    if (active !== undefined) {
        url += `active=${active}&`;
    }
    if (sort) {
        url += `sort=${sort}&`;
    }
    if (page !== undefined) {
        url += `page=${page}&`;
    }
    if (size !== undefined) {
        url += `size=${size}&`;
    }

    url = url.slice(-1) === '&' || url.slice(-1) === '?' ? url.slice(0, -1) : url;

    return httpService.customAxios.get(url);
};

const updateUserInfos = async (
    userId: string,
    fullname: string,
    role: string
) => {

    const body = {
        fullname: fullname,
        role: role,
    };

    return httpService.customAxios.patch(`/users/${userId}`, body);
};

const updateUserPassword = async (
    userId: string,
    currentPassword: string,
    newPassword: string
) => {

    const body = {
        current_password: currentPassword,
        confirm_password: newPassword,
    };

    return httpService.customAxios.put(`/change-password/${userId}`, body);
};


const assignPermissions = async (
    roleId: string,
    permissionsId: []
) => {

    return httpService.customAxios.patch(`/roles/${roleId}/assign-permissions`, permissionsId);
};

const createRole = async (
    name: string,
    description: string
) => {

    const body = {
        name: name,
        description: description
    };

    return httpService.customAxios.post("/roles", body);
};

const registration = async (
    email: string,
    fullname: string,
    password: string,
    role: string
) => {

    const body = {
        email: email,
        fullname: fullname,
        password: password,
        role: role,
    };

    return httpService.customAxios.post("/users", body);
};

const login = async (email: string, password: string) => {

    const body = {
        email: email,
        password: password,
    };

    return httpService.customAxios.post(
        "/login",
        body,
    );
};

let saveToken = (token: string) => {
    localStorage.setItem("token", token);
};

let saveUser = (user: IUser) => {
    localStorage.setItem("user", JSON.stringify(user));
};

let getAuthUser = () => {
    let user = localStorage.getItem("user");
    if (user) return JSON.parse(user) as IUser;
};

let getUser = (userId: string) => {
    return httpService.customAxios.get(
        `/users/${userId}`);
};

let deleteUser = () => {
    localStorage.removeItem("user");
};

let logout = () => {
    localStorage.removeItem("token");
};

let isLogged = () => {
    let token = localStorage.getItem("token");
    return !!token;
};

let getToken = () => {
    return localStorage.getItem("token");
};

let getTokenInfo = () => {
    return jwt_decode(getToken() as string);
};

export const accountService = {
    assignPermissions,
    createRole,
    updateUserPassword,
    updateUserInfos,
    getRoles,
    getAllUsers,
    registration,
    saveToken,
    logout,
    isLogged,
    getToken,
    getTokenInfo,
    deleteUser,
    saveUser,
    getUser,
    getAuthUser,
    login,
};
