import React, {useEffect, useState} from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";
import {accountService} from "../../shared/services";
import {useNavigate} from "react-router-dom";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import toast from "react-hot-toast";
import {useAuthUserContext} from "../../shared/context/AuthUserContext";
import CircularProgress from "@mui/material/CircularProgress";
import emailRegex from "../../shared/_utils/_helpers/emailRegex";
import {userRepository} from "./repository/user.repository";
import RoundGradientButton from "../../components/RoundGradientButton";
import Spacing from "../../components/Spacing";
import KTextField from "../../components/KTextField";

import {ThreeBounce} from "better-react-spinkit";
import {t} from "i18next";

const SignIn = () => {

    const yimboLogo = process.env.PUBLIC_URL + "/assets/yimba.png";
    const acceleratorLogo = process.env.PUBLIC_URL + "/assets/accelerator.png";
    const navigate = useNavigate();
    const {
        register,
        control,
        handleSubmit,
        watch,
        getValues,
        formState: {errors, isValid},
        setError,
    } = useForm();

    const {setUser, user} = useAuthUserContext();
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (accountService.isLogged()) navigate("/dashboard");
    });

    const goToSignUp = () => {
        navigate("/sign-up");
    };

    const goToHome = () => {
        navigate("/");
    };

    const handleLoginForm: SubmitHandler = async (signInData) => {
        setIsSubmitting(true);

        if (!signInData.email) {
            setError("email", {
                type: "custom",
                message: t('invalidField'),
            });
            setIsSubmitting(false);
            return;
        }

        if (!signInData.password) {
            setError("password", {
                type: "custom",
                message: t('invalidField'),
            });
            setIsSubmitting(false);
            return;
        }

        const {data, error} = await userRepository.login(
            signInData.email, signInData.password
        );

        setIsSubmitting(false);

        if (data) {
            if (data.user) {
                toast.success(t("loginSuccess"));

                setUser(data.user);
                accountService.saveToken(data.access_token);
                accountService.saveUser(data.user);

                navigate("/dashboard", {replace: true});
            } else {
                toast.error(data.errorMessage || t("errorOccurred"));
            }
        } else {
            toast.error(error || t("errorOccurred"));
        }
    };

    const loginFormRender = () => (
        <Box
            component="form"
            mt={0}
            noValidate
            onSubmit={handleSubmit(handleLoginForm)}
            sx={styles.form}
        >
            <Typography>{t("loginLegend")}</Typography>
            <Box mt={3}>
                {isSubmitting ? (
                    <div className="mt-5">
                        <ThreeBounce size={35} color="#797DF9"/>
                    </div>
                ) : (
                    <>
                        <KTextField
                            validation={register("email", {
                                required: t('requiredField'),
                                pattern: {
                                    value: emailRegex,
                                    message: t('invalidField'),
                                },
                            })}
                            placeholder="johndoe@gmail.com"
                            fullWidth
                        />
                        {errors.email && (
                            <Typography color={"red"}>{errors.email.message}</Typography>
                        )}
                        <Spacing marginBottom={"30px"}/>
                        <KTextField
                            type={"password"}
                            validation={register("password", {
                                required: t('requiredField'),
                            })}
                            placeholder="*********"
                            fullWidth
                        />
                        {errors.password && (
                            <Typography color={"red"}>{errors.password.message}</Typography>
                        )}
                        <Spacing marginBottom={"40px"}/>
                        <RoundGradientButton
                            disabled={!isValid || isSubmitting}
                            type="submit"
                            variant={"contained"}
                            color={"inherit"}
                            sx={{
                                background: isSubmitting
                                    ? "linear-gradient(to right, #C471ED, #4A90ED)"
                                    : "",
                            }}
                            size="large"
                            fullWidth
                        >
                            {isSubmitting && (
                                <>
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "#FFFFFF",
                                            marginRight: "5%",
                                        }}
                                    />
                                </>
                            )}
                            <Typography sx={{color: isSubmitting ? "#FFFFFF" : ""}}>
                                {t("login")}
                            </Typography>
                        </RoundGradientButton>
                    </>
                )}
            </Box>
        </Box>
    );

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid
                container
                component="main"
                sx={{
                    height: "100vh",
                }}
            >
                <Grid
                    item
                    xs={false}
                    sm={6}
                    md={6}
                    display={{xs: "none", sm: "flex", backgroundColor: "#f5f7fb"}}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 10,
                            mr: 10,
                            mt: 5,
                            flex: 1,
                            justifyContent: "space-between"
                        }}
                    >
                        <Box>
                            <Box
                                component="img"
                                src={acceleratorLogo}
                                sx={{
                                    cursor: "pointer",
                                    width: "50%"
                                }}
                                onClick={goToHome}
                            />
                            <Box
                                component="img"
                                src={yimboLogo}
                                sx={{
                                    cursor: "pointer",
                                    width: "50%"
                                }}
                                onClick={goToHome}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <Typography sx={{fontSize: "2.5rem", fontWeight: 700, mt: -20}}>
                                {t("welcomeToYimba")}
                            </Typography>
                            <Spacing marginBottom={"10px"}/>
                        </Box>
                        <Box
                            sx={{
                                alignSelf: "center",
                                alignItems: "center",
                                marginBottom: "1%"
                            }}
                        >
                            <Copyright/>
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    display={{backgroundColor: "#e2ebf9"}}
                    component={Paper}
                >
                    <Box sx={styles.formSection}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: {
                                    xs: "space-between",
                                    sm: "right"
                                },
                                alignItems: "center",
                            }}
                        >
                            <Box
                                xs="false"
                                component="img"
                                src={yimboLogo}
                                sx={{
                                    display: {
                                        sm: "none"
                                    },
                                    width: "30%",
                                    cursor: "pointer"
                                }}

                                onClick={goToHome}
                            />
                        </Box>
                        <Box
                            sx={{
                                mt: 10,
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                justifyContent: "center",
                                margin: "auto",
                            }}
                        >
                            <Typography sx={{fontSize: "2.2rem", fontWeight: 700}}>
                                {t("welcomeBack")}
                            </Typography>
                            {loginFormRender()}
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                margin: "auto",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "1.1rem", fontWeight: 400, color: "#001021", display: {
                                    },
                                }}
                            >
                                {t("newToPayqin")}
                            </Typography>
                            <Button
                                onClick={goToSignUp}
                                variant={"text"}
                                color={"inherit"}
                                sx={{
                                    fontSize: "1.1rem",
                                    color: "#4b87df",
                                    textTransform: "revert",
                                    textDecoration: 'underline',
                                    ml: 3,
                                }}
                            >
                                {t("createAccount")}
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export const Copyright = (props: any) => {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="https://yimba.com/">
                Yimba
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

export const defaultTheme = createTheme();

export default SignIn;


/**
 * @type
    {
        import("@mui/material").SxProps
    }
 */
const styles = {
    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 5,
    },
    formSection: {
        display: "flex",
        height: "75vh",
        flexDirection: "column",
        ml: {
            md: 10,
            xs: 5,
        },
        mr: {
            md: 10,
            xs: 5,
        },
        mt: 5,
    },
};
