import {IUser} from "../../containers/Auth/models/auth.response";
import React, {createContext, useContext, useEffect, useState} from "react";
import {accountService} from "../services";

interface IAuthUserContext {
    user: IUser | null;
    setUser: (user: IUser | null) => void;
}

const AuthUserContext = createContext<IAuthUserContext>({
    user: null,
    setUser: () => {},
});

export function useAuthUserContext() {
    const authUserContext = useContext(AuthUserContext);

    if (!authUserContext) {
        throw new Error('useUser must be used within a j');
    }
    return authUserContext;
}

export const AuthUserProvider: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
    const storedUsername = accountService.getAuthUser();

    const [user, setUser] = useState<IUser | null>(() => {
        return storedUsername ? storedUsername : null;
    });

    return (
        <AuthUserContext.Provider value={{user, setUser}}>
            {children}
        </AuthUserContext.Provider>
    );
};