import React, {ChangeEvent} from 'react';
import {ISocialMediaItemProps} from "./Post";
import {ISocialMedia} from "../index";

const SocialMediaShortStat = ({data, socialMedia, onChange}: {
    socialMedia: ISocialMedia
    data: ISocialMediaItemProps[]
    onChange: (socialMedia: ISocialMedia) => any
}) => {

    const kOnChange = (event: ChangeEvent<HTMLInputElement>) => {

        socialMedia.checked = event.target.checked
        onChange(socialMedia);
    };

    return (
        // <div className="card flex-fill bg-light cursor-grab border">
        <div className="my-2">
            {/*<div className="">*/}
                <div className="float-end me-n2">
                    <label className="form-check">
                        <input type="checkbox" checked={socialMedia.checked} className="form-check-input" onChange={(event) => kOnChange(event)}/>
                            <span className="form-check-label d-none">Checkbox</span>
                    </label>
                </div>
                <div className="d-flex align-content-center">
                    {socialMedia.icon}
                    <h6>{socialMedia.name} ({data.length})</h6>
                </div>

                {/*<div className="row justify-content-center">*/}
                {/*    <div className="col-6">*/}
                {/*        <div className="d-flex align-items-center">*/}
                {/*            <strong>*/}
                {/*                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"*/}
                {/*                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"*/}
                {/*                     stroke-linejoin="round" className="feather feather-bookmark align-middle me-2">*/}
                {/*                    <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>*/}
                {/*                </svg>*/}
                {/*                Resulats</strong>*/}
                {/*            <h4 className="text-muted">*/}
                {/*                {data.length}*/}
                {/*            </h4>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="col-6  justify-content-center">*/}
                {/*        <div className="d-flex align-items-baseline justify-content-center">*/}
                {/*            <strong>*/}
                {/*                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"*/}
                {/*                     viewBox="0 0 24 24" fill="none" stroke="currentColor"*/}
                {/*                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round"*/}
                {/*                     className="feather feather-repeat align-middle me-1 mb-1">*/}
                {/*                    <polyline points="17 1 21 5 17 9"></polyline>*/}
                {/*                    <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>*/}
                {/*                    <polyline points="7 23 3 19 7 15"></polyline>*/}
                {/*                    <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>*/}
                {/*                </svg>*/}
                {/*                Commentaires</strong>*/}
                {/*            <h4 className="text-muted">{data.map(socialMedia => socialMedia.commentsCount).reduce((total, count) => total + count, 0)}</h4>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="col-6">*/}
                {/*        <div className="d-flex align-items-baseline">*/}
                {/*            <strong>*/}
                {/*                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"*/}
                {/*                     viewBox="0 0 24 24" fill="none" stroke="currentColor"*/}
                {/*                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round"*/}
                {/*                     className="feather feather-share-2 align-middle me-1 mb-1">*/}
                {/*                    <circle cx="18" cy="5" r="3"></circle>*/}
                {/*                    <circle cx="6" cy="12" r="3"></circle>*/}
                {/*                    <circle cx="18" cy="19" r="3"></circle>*/}
                {/*                    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>*/}
                {/*                    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>*/}
                {/*                </svg>*/}
                {/*                Partages</strong>*/}
                {/*            <h4 className="text-muted">{data.map(socialMedia => socialMedia.sharesCount).reduce((total, count) => total + count, 0)}</h4>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="col-6">*/}
                {/*        <div className="d-flex align-items-baseline">*/}
                {/*            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"*/}
                {/*                 viewBox="0 0 24 24" fill="none" stroke="currentColor"*/}
                {/*                 stroke-width="2" stroke-linecap="round" stroke-linejoin="round"*/}
                {/*                 className="feather feather-heart align-middle me-1 mb-1">*/}
                {/*                <path*/}
                {/*                    d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>*/}
                {/*            </svg>*/}
                {/*            <strong>*/}
                {/*                Likes</strong>*/}
                {/*            <h4 className="text-muted">*/}
                {/*                {data.map(socialMedia => socialMedia.likesCount).reduce((total, count) => total + count, 0)}*/}
                {/*            </h4>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            {/*</div>*/}
        </div>
    );
};

export default SocialMediaShortStat;
