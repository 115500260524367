import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {Toaster} from "react-hot-toast";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {AuthUserProvider} from "./shared/context/AuthUserContext";
import {I18nextProvider, initReactI18next} from "react-i18next";
import i18next from "i18next";
import en from "./i18n/en/translation.json";
import fr from "./i18n/fr/translation.json";
import IdleTimerContainer from "./components/IdleTimer";
import {BrowserRouter} from "react-router-dom";

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const lang = localStorage.getItem("language") || navigator.language;

i18next.use(initReactI18next).init({
    lng: lang,
    debug: true,
    resources: {
        en: {
            translation: en,
        },
        fr: {
            translation: fr,
        },
    },
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <I18nextProvider i18n={i18next}>
                <AuthUserProvider>
                    <IdleTimerContainer></IdleTimerContainer>
                    <Toaster/>
                    <ToastContainer />
                    <App/>
                </AuthUserProvider>
            </I18nextProvider>
        </BrowserRouter>
    </React.StrictMode>
);
