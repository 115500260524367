export const renderTabListItem = (tabId: string, title: string, isActive: boolean, handleTabChange: Function) => {
    return (
        <li className="nav-item" role="presentation" key={tabId}>
            <a
                className={`nav-link ${isActive ? 'active' : ''}`}
                href={`#${tabId}`}
                onClick={() => handleTabChange(tabId)}
            >
                {title}
            </a>
        </li>
    );
};

type TabChangeHandler = (tabId: string, projectName?: string) => Promise<void>;

export const renderTabList = (activeTab: string, handleTabChange: TabChangeHandler) => {
    return (
        <ul className="nav nav-tabs" role="tablist">
            {[
                { id: 'tab-1', title: 'Mentions' },
                { id: 'tab-2', title: 'Statistiques' },
                // { id: 'tab-3', title: 'Rapport' },
            ].map(tab => {
                const isActive = activeTab === tab.id;
                return renderTabListItem(tab.id, tab.title, isActive, handleTabChange);
            })}
        </ul>
    );
};
