import React from "react";
import "./index.css";
import {Navigate, Route, Routes} from "react-router-dom";
import AuthLayout from "./_auth/AuthLayout";
import SignIn from "./containers/Auth/SignIn";
import SignUp from "./containers/Auth/SignUp";
import RootLayout from "./_root/RootLayout";
import Project from "./containers/Project";
import Settings from "./containers/Settings";
import AddProject from "./containers/Project/Add";
import Home from "./containers/Home";
import AuthGuard from "./shared/_utils/_helpers/AuthGuard";
import Dashboard from "./containers/Dashboard";
import AddUser from "./containers/Users/Add";
import UpdateUser from "./containers/Users/Update";
import Users from "./containers/Users";

function App() {
    return (

        <Routes>
            {/* public routes */}

            <Route path="/" element={<Home/>}/>

            <Route element={<AuthLayout/>}>
                <Route path="/login" element={<SignIn/>}/>
                <Route path="/sign-up" element={<SignUp/>}/>
            </Route>

            <Route path={"/*"} element={
                <AuthGuard>
                    <>
                        <Routes>
                            <Route path="/users/create" element={<AddUser/>}/>
                            <Route path="/projects/add" element={<AddProject/>}/>

                            <Route element={<RootLayout/>}>
                                <Route path="/users" element={<Users/>}/>
                                <Route path="/users/:userId" element={<UpdateUser/>}/>
                                <Route path="/dashboard" element={<Dashboard/>}/>
                                <Route path="/projects/:project" element={<Project/>}/>
                                <Route path="/settings" element={<Settings/>}/>

                                <Route path="/*" element={<Navigate replace to={"/"}/>}/>
                            </Route>
                        </Routes>
                    </>
                </AuthGuard>
            }>

            </Route>
        </Routes>

    );
}

export default App;
