import React, {useEffect, useState} from 'react';
import Header from "../../../components/_header/Header";
import SideBar from "../../../components/_side-bar/SideBar";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import Footer from "../../../components/_footer/Footer";
import {SubmitHandler, useForm} from "react-hook-form";
import {t} from "i18next";
import {userRepository} from "../../Auth/repository/user.repository";
import {accountService} from "../../../shared/services";
import {ThreeBounce} from "better-react-spinkit";
import {IUser} from "../../../shared/types/users";
import {permissionRepository} from "../../../shared/repositories/permissions.repository";
import {IRoleInfo} from "../../../shared/types/users";
import {isEmpty} from "../../../shared/_utils/isEmpty";
import {toast} from "react-toastify";
import {ktoast} from "../../../shared/_utils/_helpers/ktoast";

const AddUsers = () => {

    const [roles, setRoles] = useState();
    const [loading, setLoading] = useState()
    const navigate = useNavigate();
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            firstname: "",
            lastname: "",
            password: "",
            email: "",
            role: "",
        },
    })

    useEffect(() => {

        setLoading(true)

        permissionRepository.getRoles()
            .then(({data, error}) => {
                if (null != data) {
                    setRoles(data.items)
                }
            })
            .finally(() => setLoading(false))

    }, [])

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async (data) => {

        if (isEmpty(data.firstname) ||
            isEmpty(data.lastname) ||
            isEmpty(data.password) ||
            isEmpty(data.email)
        ) {
            toast.error("les champs ne sont pas correctement remplis");
            return;
        }

        setIsSubmitting(true);

        const {data: resp, error} = await userRepository.registration(
            data.email,
            data.firstname + ' ' + data.lastname,
            data.password,
            data.role
        );

        if (null != resp) {

            if (resp._id) {
                ktoast.success("Le compte à été crée")

                setIsSubmitting(false);
                return goToBack()
            }
        }

        setIsSubmitting(false);
    }

    const goToBack = () => navigate("/users", {replace: true});

    const Select = React.forwardRef(({ onChange, onBlur, name, label }, ref) => (
        <>
            <label>{label}</label>
            <select className="form-select form-control-lg" name={name} ref={ref} onChange={onChange} onBlur={onBlur}>

                    <option value={""} disabled={true}>-- Sélectionnez le role --</option>

                            {roles && roles.length > 0 ? (
                                    roles.map((role) => (
                                        <option key={role._id} value={role._id}>
                                            {role.name.toUpperCase()}
                                        </option>
                        ))
                            ) : (
                                <></>
                )}

            </select>
        </>
    ))

    return (
        <div className="wrapper">
            <div className="main">
                <Header/>
                {/*<main className="content">*/}
                <div className="container-fluid">
                    <div className="row vh-100">
                        <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                            <div className="d-table-cell align-middle">

                                <div className="text-center mt-0">
                                    <h1 className="h2">Inscription d'un utilisateur</h1>
                                    <p className="lead">En soumettant ce formulaire, vous créez un utilisateur
                                    </p>
                                </div>
                                <div className="card">
                                    {isSubmitting ? (
                                        <div className="card-body m-auto">
                                            <div className="m-sm-3">
                                                <ThreeBounce size={35} color="#797DF9"/>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="card-body">
                                            <div className="m-sm-3">
                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="mb-3">
                                                        <label className="form-label">Nom</label>
                                                        <input className="form-control form-control-lg" name="lastname" placeholder="Entrez le nom" {...register("lastname")} required/>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Prénom</label>
                                                        <input className="form-control form-control-lg" name="firstname" placeholder="Entrez le prénom" {...register("firstname")} required/>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Email</label>
                                                        <input className="form-control form-control-lg" type="email" name="email" placeholder="Entrez l'adresse mail" {...register("email")} required/>
                                                    </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Mot de passe</label>
                                                            <input className="form-control form-control-lg" type="password" name="password" placeholder="Entrez un mot de passe" {...register("password")} required/>
                                                        </div>
                                                        <div className="mb-3">
                                                            <Select label="Ajouter un role" {...register("role")} />
                                                        </div>
                                                        <div className="d-grid gap-2 mt-3">
                                                            <input className="btn btn-lg btn-primary" type="submit" value="Ajouter"/>
                                                        </div>
                                                    </form>
                                                    </div>
                                                </div>
                                            )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*</main>*/}
                <Footer/>
            </div>
        </div>
    );
};

export default AddUsers;
