import {IApiResponse, ICommonApiResponse} from "../../../shared/types/IApiResponse";
import {accountService} from "../../../shared/services";
import {IAuthenticatedUserResponse, IUser,} from "../models/auth.response";
import {displayError} from "../../../shared/_utils/_helpers/error";
import {IUsersResponse} from "../../../shared/types/users";

const getAllUsers = async (page = 1, size = 10): Promise<IApiResponse<IUsersResponse | null>> => {
    try {
        const {data, status, statusText} = await accountService.getAllUsers({page, size});

        return Promise.resolve({
            data: data as IUsersResponse,
            error: null,
        });
    } catch (error) {
        console.log({error})
        return displayError(error)
    }
};

const getUser = async (userId: string): Promise<IApiResponse<IUser | null>> => {
    try {
        const {data, status, statusText} = await accountService.getUser(userId);
        console.log({data})
        return Promise.resolve({
            data: data as IUser,
            error: null,
        });
    } catch (error) {
        console.log({error})
        return displayError(error)
    }
};

const updateUserPassword = async (
    userId: string,
    currentPassword: string,
    newPassword: string
): Promise<IApiResponse<IAuthenticatedUserResponse | null>> => {
    try {
        const {data, status, statusText} = await accountService.updateUserPassword(userId,
            currentPassword,
            newPassword
        );

        console.log({data})

        return Promise.resolve({
            data: data as IAuthenticatedUserResponse,
            error: null,
        });
    } catch (error) {
        console.log({error})
        return displayError(error)
    }
};

const updateUserInfos = async (
    userId: string,
    fullname: string,
    role: string
): Promise<IApiResponse<IAuthenticatedUserResponse | null>> => {
    try {
        const {data, status, statusText} = await accountService.updateUserInfos(userId,
            fullname,
            role
        );

        console.log({data})

        return Promise.resolve({
            data: data as IAuthenticatedUserResponse,
            error: null,
        });
    } catch (error) {
        console.log({error})
        return displayError(error)
    }
};

const registration = async (
    email: string,
    fullname: string,
    password: string,
    role: string
): Promise<IApiResponse<IAuthenticatedUserResponse | null>> => {
    try {
        const {data, status, statusText} = await accountService.registration(email,
            fullname,
            password,
            role
        );

        return Promise.resolve({
            data: data as IAuthenticatedUserResponse,
            error: null,
        });
    } catch (error) {
        console.log({error})
        return displayError(error)
    }
};

const login = async (
    email: string,
    password: string,
): Promise<IApiResponse<IAuthenticatedUserResponse | null>> => {
    try {
        const {data, status, statusText} = await accountService.login(email, password);
        return Promise.resolve({
            data: data as IAuthenticatedUserResponse,
            error: null,
        });
    } catch (error) {
        return displayError(error)
    }
};

export const userRepository = {
    updateUserPassword,
    updateUserInfos,
    getUser,
    getAllUsers,
    registration,
    login
};
