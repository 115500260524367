import {Outlet} from "react-router-dom";
import React from "react";
import SideBar from "../components/_side-bar/SideBar";
import Footer from "../components/_footer/Footer";
import Header from "../components/_header/Header";

const RootLayout = () => {
    return (
        <div className="wrapper">
            <SideBar/>
            <div className="main">
                <Header/>
                <main className="content">
                    <Outlet/>
                </main>
                <Footer/>
            </div>
        </div>
    );
};

export default RootLayout;
