import {projectService} from "../../../shared/services";
import {
  IApiResponse,
  ICommonApiResponse,
  IMentionResponse,
  IProjectApiResponse
} from "../../../shared/types/IApiResponse";
import {
  IDataContainer,
  IDataInstagramApiResponse,
  IDataFacebookApiResponse,
  IProjectsListResponse,
  IDataTiktokApiResponse, IDataTwitterApiResponse, IDataGoogleApiResponse, IDataYoutubeApiResponse, ITagsCloudResponse
} from "../models/projects.response";
import {displayError} from "../../../shared/_utils/_helpers/error";
import {ISourceData, ISourceOption} from "../../../shared/types/sources";
import {ISearchRequest} from "../../../shared/types/searchRequest";
import {ILanguage, ILanguagesResponse} from "../../../shared/types/languages";

const getRapport = async (wordToSearch: string): Promise<IApiResponse<any|null>> => {
  try {
    console.log("========================")
    console.log({wordToSearch})
    console.log("===================")
    const { data, status, statusText } = await projectService.getRapport(
        wordToSearch
    );

    console.log("+++++++++++++++++++++++")
    console.log({data})
    console.log("===================")

    return Promise.resolve({
      data: data,
      error: null,
    });
  } catch (error) {
    return Promise.resolve({
      data: null,
      error: "Une erreur est survenu pendant le génération du rapport",
    });
  }
};


const getSources = async (): Promise<IApiResponse<ISourceData|null>> => {
  try {
    const { data, status, statusText } = await projectService.getSources();


    console.log({data})
    return Promise.resolve({
      data: data as ISourceData,
      error: null,
    });
  } catch (error) {
    displayError(error)
    console.log({error})
    return Promise.resolve({
      data: null,
      error: "Une erreur est survenu lors de la generation du nuage de mots via facebook",
    });
  }
};

const getLanguages = async (): Promise<IApiResponse<ILanguagesResponse|null>> => {
  try {
    const { data, status, statusText } = await projectService.getLanguages();


    console.log({data})
    return Promise.resolve({
      data: data as ILanguagesResponse,
      error: null,
    });
  } catch (error) {
    displayError(error)
    console.log({error})
    return Promise.resolve({
      data: null,
      error: "Une erreur est survenu lors de la generation du nuage de mots via facebook",
    });
  }
};

const getProject = async (projectId: string, page = 1, size = 10): Promise<IApiResponse<IMentionResponse | null>> => {
  try {
    const { data, status, statusText } = await projectService.getProject({
      projectId,
      page,
      size
    });
    console.log(data);
    return Promise.resolve({
      data: data as IMentionResponse,
      error: null,
    });
  } catch (error) {
    console.log({error})
    displayError(error)
    return Promise.resolve({
      data: null,
      error: "Une erreur est survenu lors de la recupération du projet",
    });
  }
};

const projectsList = async ({userId, size, sort, page}: { userId: string, active?: boolean; sort?: string; page?: number; size?: number }): Promise<IApiResponse<IProjectsListResponse | null>> => {
  try {
    const { data, status, statusText } = await projectService.projectsList(
        {userId, page, size}
    );

    console.log(data);
    return Promise.resolve({
      data: data as IProjectsListResponse,
      error: null,
    });
  } catch (error) {
    console.log({error})
    displayError(error)
    return Promise.resolve({
      data: null,
      error: "Une erreur est survenu lors de la recupération des projets",
    });
  }
};

const createProject = async (searchRequest: ISearchRequest) => {
  try {
    const { data, status, statusText } = await projectService.createProject(searchRequest);
    console.log(data);
    return Promise.resolve({
      data: data as ICommonApiResponse,
      error: null,
    });
  } catch (error) {
    console.log({error})
    displayError(error)
    return Promise.resolve({
      data: null,
      error: "Une erreur est survenu lors de la création du projet,",
    });
  }
};

export const projectRepository = {
  getLanguages,
  getSources,
  getRapport,
  getProject,
  projectsList,
  createProject,
};
