import React, {useEffect, useState} from 'react';
import {useLoaderData, useLocation, useNavigate, useParams} from "react-router-dom";
import {IAlert, IAlertResponse} from "../Project/models/projects.response";
import {accountService} from "../../shared/services";
import {projectRepository} from "../Project/repository/project.repository";
import toast from "react-hot-toast";
import formatDate from "../../shared/_utils/_helpers/formatDate";
import Pagination from "../../components/pagination";

const Dashboard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>()

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalUsers, setTotalUsers] = useState(0);

    const addProjectIllustration = process.env.PUBLIC_URL + "/assets/new-project.png";

    const goToNewProject = () => {
        navigate("/projects/add")
    }

    const goToProject = (id: string) => {
        return navigate(`/projects/${id}`, {state: {previousLocationPathname: location.pathname, newProject: false}})
    }

    const [projectsList, setProjectsList] = useState<IAlertResponse[]>();

    useEffect(() => {
        const user = accountService.getAuthUser();
        if (!user) {
            return;
        }

        console.log({user})

        setLoading(true)
        projectRepository.projectsList({
            userId: user._id,
            page: currentPage,
            size: pageSize
        })
            .then(({data, error}) => {
                if (null != data) {
                    console.log({data})
                    setProjectsList(data.items)
                    setTotalUsers(data.total)
                    setCurrentPage(data.page)
                } else {
                    if (error) {
                        toast.error(error)
                    }
                }
            })
            .finally(() => setLoading(false))
    }, [pageSize, currentPage])

    const goToDashboard = () => {
        navigate("/dashboard")
    }

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (event: { target: { value: string; }; }) => {
        setPageSize(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };


    return (
        <div className="container-fluid p-0">
            <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                    <h3>Historiques des alertes</h3>
                </div>

                <div className="col-auto ms-auto text-end mt-n1">
                    <a onClick={goToNewProject} className="btn btn-primary">Créer une alerte</a>
                </div>
            </div>
            <div>


                <div className="card">
                    <div className="card-body">
                        <div id="datatables-orders_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <div className="dataTables_length" id="datatables-orders_length"><label>Afficher
                                        <select value={pageSize} onChange={handlePageSizeChange}
                                                name="datatables-orders_length" aria-controls="datatables-orders"
                                                className="form-select form-select-sm">
                                            <option value="2">2</option>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </label>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="row dt-row">
                                <div className="col-sm-12">
                                    {
                                        loading
                                            ? <div className="flex-column text-center my-7">
                                                <div className="spinner-border text-secondary me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                            : projectsList && projectsList.length > 0 ?
                                            <table className="table table-borderless my-0">
                                                <thead>
                                                <tr>
                                                    <th>Nom</th>
                                                    <th className="d-none d-xxl-table-cell">Crée le</th>
                                                    <th className="d-none d-xl-table-cell">Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    projectsList.map(project => <tr>
                                                        <td>
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">
                                                                    <strong>{project.alert.name}</strong>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="d-none d-xxl-table-cell">
                                                            <div className="text-muted">
                                                                {formatDate(project.created_at)}
                                                            </div>
                                                        </td>
                                                        <td className="d-none d-xl-table-cell">
                                                            <a onClick={() => goToProject(project.alert.id)}
                                                               className="btn btn-light">Voir</a>
                                                        </td>
                                                    </tr>)
                                                }
                                                </tbody>
                                            </table>
                                            :
                                            <div className="flex-column text-center my-7">
                                                <div><h5>Aucun projet</h5></div>
                                                <img className="img-fluid" src={addProjectIllustration}/>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-5">
                                    <div className="dataTables_info" id="datatables-orders_info" role="status" aria-live="polite">
                                        {`Showing ${Math.min((currentPage - 1) * pageSize + 1, totalUsers)} to ${Math.min(currentPage * pageSize, totalUsers)} of ${totalUsers} entries`}
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-7">
                                    <Pagination
                                        currentPage={currentPage}
                                        totalItems={totalUsers}
                                        pageSize={pageSize}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
