import React, {useEffect, useState} from 'react';
import Header from "../../../components/_header/Header";
import SideBar from "../../../components/_side-bar/SideBar";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import Footer from "../../../components/_footer/Footer";
import {SubmitHandler, useForm} from "react-hook-form";
import {t} from "i18next";
import {userRepository} from "../../Auth/repository/user.repository";
import {accountService} from "../../../shared/services";
import {IUser} from "../../../shared/types/users";
import {permissionRepository} from "../../../shared/repositories/permissions.repository";
import {IRoleInfo} from "../../../shared/types/users";
import {isEmpty} from "../../../shared/_utils/isEmpty";
import {toast} from "react-toastify";
import {FaEye, FaEyeSlash} from "react-icons/fa";

const UpdateUser = () => {

    const { userId } = useParams();
    const [roles, setRoles] = useState();
    const [loading, setLoading] = useState()
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showVerifyPassword, setShowVerifyPassword] = useState(false);

    const {
        register: registerInfo,
        handleSubmit: handleSubmitInfo,
        setValue: setValueInfo,
    } = useForm({
        defaultValues: {
            fullname: '',
            email: '',
            role: '',
        },
    });

    const {
        register: registerPassword,
        handleSubmit: handleSubmitPassword,
        setValue: setValuePassword,
    } = useForm({
        defaultValues: {
            currentPassword: '',
            newPassword: '',
            verifyPassword: '',
        },
    });

    useEffect(() => {

        setLoading(true)

        permissionRepository.getRoles()
            .then(({data, error}) => {
                if (null != data) {
                    setRoles(data.items)
                }
            })
            .finally(() => setLoading(false))
        console.log("==========", userId)
        if (userId) {
            userRepository.getUser(userId)
                .then(({ data }) => {
                    if (data) {
                        setValueInfo("fullname", data.fullname);
                        setValueInfo("email", data.email);
                        setValueInfo("role", data.role);
                    }
                });
        }

    }, [])

    const onSubmitInfo = async (data) => {

        if (isEmpty(data.fullname) ||
            isEmpty(data.email)
        ) {
            toast.error("les champs ne sont pas correctement remplis");
            return;
        }

        setIsSubmitting(true);

        const {data: resp, error} = await userRepository.updateUserInfos(
            userId,
            data.fullname ,
            data.role
        );

        if (null != resp) {

            if (resp._id) {
                toast.success("Les modifications on été enrégistrés")

                setIsSubmitting(false);
                // return goToBack()
            }
        }

        setIsSubmitting(false);
    }

    const onSubmitPassword = async(data) => {
        const currentPassword = data.currentPassword.replace(/\s+/g, "")
        const newPassword = data.newPassword.replace(/\s+/g, "")
        const verifyPassword = data.verifyPassword.replace(/\s+/g, "")



        console.log({currentPassword})
        console.log({newPassword})
        console.log({verifyPassword})

        if (newPassword != verifyPassword)
        {
            toast.error("Les mots de passe ne sont pas identiques");
            return;
        }

        if (isEmpty(currentPassword) ||
            isEmpty(newPassword) ||
            isEmpty(verifyPassword)
        ) {
            toast.error("les champs ne sont pas correctement remplis");
            return;
        }

        setIsSubmitting(true);

        const {data: resp, error} = await userRepository.updateUserPassword(
            userId,
            currentPassword ,
            newPassword
        );

        if (null != resp) {

            if (resp._id) {
                toast.success("Les modifications on été enrégistrés")

                setIsSubmitting(false);
                // return goToBack()
            }
        }

        setIsSubmitting(false);
    };

    const goToBack = () => navigate("/users", {replace: true});

    const Select = React.forwardRef(({ onChange, onBlur, name, label }, ref) => (
        <>
            <label>{label}</label>
            <select className="form-select form-control-lg" name={name} ref={ref} onChange={onChange} onBlur={onBlur}>

                <option value={""} disabled={true}>-- Sélectionnez le role --</option>

                {roles && roles.length > 0 ? (
                    roles.map((role) => (
                        <option key={role._id} value={role._id}>
                            {role.name.toUpperCase()}
                        </option>
                    ))
                ) : (
                    <></>
                )}

            </select>
        </>
    ))

    return (
            <div className="container-fluid p-0">

                <h1 className="h3 mb-3">Modification des informations de l'utilisateur</h1>

                <div className="row">
                    {/*<div className="col-md-3 col-xl-2">*/}

                    {/*    <div className="card">*/}
                    {/*        <div className="card-header">*/}
                    {/*            <h5 className="card-title mb-0">Profile Settings</h5>*/}
                    {/*        </div>*/}

                    {/*        <div className="list-group list-group-flush" role="tablist">*/}
                    {/*            <a className="list-group-item list-group-item-action active" data-bs-toggle="list" href="#account" role="tab" aria-selected="true">*/}
                    {/*                Account*/}
                    {/*            </a>*/}
                    {/*            <a className="list-group-item list-group-item-action" data-bs-toggle="list" href="#password" role="tab" aria-selected="false" tabIndex="-1">*/}
                    {/*                Password*/}
                    {/*            </a>*/}
                    {/*            <a className="list-group-item list-group-item-action" data-bs-toggle="list" href="#" role="tab" aria-selected="false" tabIndex="-1">*/}
                    {/*                Privacy and safety*/}
                    {/*            </a>*/}
                    {/*            <a className="list-group-item list-group-item-action" data-bs-toggle="list" href="#" role="tab" aria-selected="false" tabIndex="-1">*/}
                    {/*                Email notifications*/}
                    {/*            </a>*/}
                    {/*            <a className="list-group-item list-group-item-action" data-bs-toggle="list" href="#" role="tab" aria-selected="false" tabIndex="-1">*/}
                    {/*                Web notifications*/}
                    {/*            </a>*/}
                    {/*            <a className="list-group-item list-group-item-action" data-bs-toggle="list" href="#" role="tab" aria-selected="false" tabIndex="-1">*/}
                    {/*                Widgets*/}
                    {/*            </a>*/}
                    {/*            <a className="list-group-item list-group-item-action" data-bs-toggle="list" href="#" role="tab" aria-selected="false" tabIndex="-1">*/}
                    {/*                Your data*/}
                    {/*            </a>*/}
                    {/*            <a className="list-group-item list-group-item-action" data-bs-toggle="list" href="#" role="tab" aria-selected="false" tabIndex="-1">*/}
                    {/*                Delete account*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="col-md-9 col-xl-10">
                        <div className="tab-content">
                            <div className="tab-pane fade active show" id="account" role="tabpanel">

                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">Informations de compte</h5>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmitInfo(onSubmitInfo)}>
                                            <div className="row">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="inputFirstName">Nom complet</label>
                                                    <input {...registerInfo("fullname")} required type="text" className="form-control" id="inputFirstName" placeholder="First name" />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Email</label>
                                                <input className="form-control form-control-lg" type="email" name="email" placeholder="Entrez l'adresse mail" {...registerInfo("email")} required/>
                                            </div>
                                            <div className="mb-3">
                                                <Select label="Ajouter un role" {...registerInfo("role")} />
                                            </div>
                                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Enrégistrer les modifications</button>
                                        </form>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Mot de passe</h5>

                                        <form onSubmit={handleSubmitPassword(onSubmitPassword)}>
                                            <div className="mb-3 position-relative">
                                                <label className="form-label" htmlFor="inputPasswordCurrent">Current password</label>
                                                <input
                                                    type={showCurrentPassword ? 'text' : 'password'}
                                                    className="form-control"
                                                    id="inputPasswordCurrent"
                                                    {...registerPassword('currentPassword')}
                                                />
                                                <span
                                                    type="button"
                                                    className="position-absolute end-0 m-3 top-50 translate-middle-y"
                                                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                                >
                                                    {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
                                                </span>
                                            </div>
                                            <div className="mb-3 position-relative">
                                                <label className="form-label" htmlFor="inputPasswordNew">New password</label>
                                                <input
                                                    type={showNewPassword ? 'text' : 'password'}
                                                    className="form-control"
                                                    id="inputPasswordNew"
                                                    {...registerPassword('newPassword')}
                                                />
                                                <span
                                                    type="button"
                                                    className="position-absolute end-0 m-3 top-50 translate-middle-y"
                                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                                >
                                                    {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                                                </span>
                                            </div>
                                            <div className="mb-3 position-relative">
                                                <label className="form-label" htmlFor="inputPasswordNew2">Verify password</label>
                                                <input
                                                    type={showVerifyPassword ? 'text' : 'password'}
                                                    className="form-control"
                                                    id="inputPasswordNew2"
                                                    {...registerPassword('verifyPassword')}
                                                />
                                                <span
                                                    type="button"
                                                    className="position-absolute end-0 m-3 top-50 translate-middle-y"
                                                    onClick={() => setShowVerifyPassword(!showVerifyPassword)}
                                                >
                                                    {showVerifyPassword ? <FaEyeSlash /> : <FaEye />}
                                                </span>
                                            </div>
                                            <button type="submit" disabled={isSubmitting} className="btn btn-primary">Save changes</button>
                                        </form>

                                    </div>
                                </div>

                            </div>
                            <div className="tab-pane fade" id="password" role="tabpanel">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Password</h5>

                                        <form>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="inputPasswordCurrent">Mot de passe actuel</label>
                                                <input type="password" className="form-control" id="inputPasswordCurrent" />
                                                <small><a href="#">Forgot your password?</a></small>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="inputPasswordNew">Nouveau mot de passe</label>
                                                <input type="password" className="form-control" id="inputPasswordNew" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="inputPasswordNew2">Rétapez votre nouveau mot de password</label>
                                                <input type="password" className="form-control" id="inputPasswordNew2" />
                                            </div>
                                            <button type="submit" className="btn btn-primary">Enrégistrer les modifications</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
    );
};

export default UpdateUser;
