import React, {useEffect, useRef, useState} from "react";
import {Box, IconButton, IconButtonProps, Typography,} from "@mui/material";
import {isEmpty} from "../../shared/_utils/isEmpty";
import {useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {projectRepository} from "./repository/project.repository";
import {styled} from "@mui/material/styles";
import {
    IAnalyse,
    IDataFacebookApiResponse,
    IDataGoogleApiResponse,
    IDataInstagramApiResponse,
    IDataTiktokApiResponse,
    IDataTwitterApiResponse,
    IDataYoutubeApiResponse, ISentimentInfo
} from "./models/projects.response";
import {ISocialMediaItemProps, IUser} from "./components/Post";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import SocialMediaItem from "./components/SocialMediaItem";
import ActionPostStatisticCard from "./components/ActionPostStatisticCard";
import ScreenLoader from "./components/ScreenLoader";
import SocialMediaShortStat from "./components/SocialMediaShortStat";
import {IItem, IMentionResponse, IProjectApiResponse} from "../../shared/types/IApiResponse";
import {
    FacebookIcon,
    GoogleIcon,
    InstagramIcon,
    TiktokIcon,
    TwitterIcon,
    YoutubeIcon
} from "../../shared/_utils/constants/icon";
import SocialMediaFullStat from "./components/SocialMediaFullStat";
import AnalysisStat from "./components/AnalysisStat";
import {negatif, neutre, positif} from "../../shared/_utils/_helpers/Analysis";
import Chart from "./components/Chart";
import CloudTags from "./components/CloudTags";
import extractBody from "../../shared/_utils/_helpers/ExtractBody";
import ResponseViewer from "../../shared/_utils/_helpers/ResponseViewer";

import "./components/rapport.css"
import {renderTabList} from "./components/renderTabListItem";
import KDateRange, {IDateRangeProps} from "./components/KDateRange";
import BuildCloudTag from "./components/BuildCloudTag";
import generatePDF, {usePDF, Resolution, Margin, Options} from "react-to-pdf";
import AllCloudTags from "./components/AllCloudTags";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PDFViewer } from '@react-pdf/renderer';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import Pagination from "../../components/pagination";

interface INewProjectForm {
    text: string;
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

export interface ISocialMedia {
    id: string;
    name: string;
    icon: JSX.Element;
    checked: boolean;
}

const sources: ISocialMedia[] = [
    {id: "facebook", name: 'Facebook', icon: <FacebookIcon/>, checked: true},
    {id: "googlesearch", name: 'Google search', icon: <GoogleIcon/>, checked: true},
    {id: "instagram", name: 'Instagram', icon: <InstagramIcon/>, checked: true},
    {id: "tiktok", name: 'TikTok', icon: <TiktokIcon/>, checked: true},
    {id: "twitter", name: 'X', icon: <TwitterIcon/>, checked: true},
    {id: "youtube", name: 'Youtube', icon: <YoutubeIcon/>, checked: true},
];

type SourceAction = (wordToSearch: string) => Promise<ISocialMediaItemProps[] | undefined | void>;

export const faceBook = {id: "facebook", name: 'Facebook', icon: <FacebookIcon/>, checked: true} as ISocialMedia
export const googleSearch = {
    id: "googlesearch",
    name: 'Google search',
    icon: <GoogleIcon/>,
    checked: true
} as ISocialMedia
export const instagram = {id: "instagram", name: 'Instagram', icon: <InstagramIcon/>, checked: true} as ISocialMedia
export const tiktok = {id: "tiktok", name: 'Tiktok', icon: <TiktokIcon/>, checked: true} as ISocialMedia
export const twitter = {id: "twitter", name: 'Twitter', icon: <TwitterIcon/>, checked: true} as ISocialMedia
export const youtube = {id: "youtube", name: 'Youtube', icon: <YoutubeIcon/>, checked: true} as ISocialMedia
export const allSocialsMedias = {
    id: "allSocialsMedias",
    name: 'allSocialsMedias',
    icon: <YoutubeIcon/>,
    checked: true
} as ISocialMedia

const Project = () => {

    const [newProjectDialog, setNewProjectDialog] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [project, setProject] = useState<IProjectApiResponse>();
    const [searchText, setSearchText] = useState("");
    const [wordToSearch, setWordToSearch] = useState("");
    const [posts, setPosts] = useState<IItem[]>([]);
    const [filteredPosts, setFilteredPosts] = useState<IItem[]>([]);
    const [postsFromFacebook, setPostsFromFacebook] = useState<ISocialMediaItemProps[]>([]);
    const [postsFromInstagram, setPostsFromInstagram] = useState<ISocialMediaItemProps[]>([]);
    const [postsFromTwitter, setPostsFromTwitter] = useState<ISocialMediaItemProps[]>([]);
    const [postsFromTiktok, setPostsFromTiktok] = useState<ISocialMediaItemProps[]>([]);
    const [postsFromGoogle, setPostsFromGoogle] = useState<ISocialMediaItemProps[]>([]);
    const [postsFromYoutube, setPostsFromYoutube] = useState<ISocialMediaItemProps[]>([]);
    const [errorsFetch, setErrorsFetch] = useState<string>();
    const [selectedSources, setSelectedSources] = useState<ISocialMedia[]>([faceBook, instagram, twitter, tiktok, youtube, googleSearch]);
    const [negativeCount, setNegativeCount] = useState<number>();
    const [neutralCount, setNeutralCount] = useState<number>();
    const [positiveCount, setPositiveCount] = useState<number>();
    const [cloudTags, setCloudTags] = useState<string>();
    const [activeTab, setActiveTab] = useState<string>('tab-1');
    const [loadingRapportsTab, setLoadingRapportsTab] = useState<boolean>(false);
    const [fromDate, setFromDate] = useState<string>();
    const [toDate, setToDate] = useState<string>();
    const [rapport, setRapport] = useState<string>();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalMentions, setTotalMentions] = useState(0);


    const {
        register,
        control,
        handleSubmit,
        watch,
        getValues,
        setValue,
        clearErrors,
        formState: {errors, isValid},
        setError,
    } = useForm<INewProjectForm>();

    const {project: projectId} = useParams();
    const location = useLocation();
    const { previousLocationPathname, newProject } = location.state || {};

    const handleTabChange = async (tabId: string, projectName?: string) => {
        setActiveTab(tabId);
        switch (tabId) {
            case 'tab-1':
                return loadMentionsTab(projectName);
            case 'tab-2':
                return loadStatisticsTab(projectName);
            case 'tab-3':
                return loadRapportsTab(projectName);
            default:
                return loadMentionsTab(projectName);
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (event: { target: { value: string; }; }) => {
        setPageSize(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };

    const loadMentionsTab = (projectName?: string) => {

    }

    const loadStatisticsTab = (projectName?: string) => {

    }

    const loadRapportsTab = async (projectName?: string) => {

        if (!projectName) {
            toast.error("Le nom du projet semble être introuvable ou pose des problèmes")
            return;
        }

        setLoadingRapportsTab(true)

        const {data, error} = await projectRepository.getRapport(projectName);

        setLoadingRapportsTab(false)

        if (error) {
            setRapport("")
            return;
        }

        const body = extractBody(data)

        setRapport(body)
    }

    useEffect(() => {
        resetData()

        if (!projectId || isEmpty(projectId)) return;

        setIsSubmitting(true)

        projectRepository.getProject(projectId, currentPage, pageSize)
            .then((projectResponse) => {
                const _project = projectResponse.data;

                console.log("=================")
                console.log({projectResponse})
                console.log("=================")

                // if (project) {
                //     handleTabChange(activeTab, _project?.name).then()
                // }

                if (!_project) {
                    toast.error("Project not found");
                    return;
                }

                setPosts(_project.items);
                setTotalMentions(_project.total)

                // setProject(_project);


                // Retourner la promesse de la recherche pour l'inclure dans Promise.all
            })
            .then((searchResults) => {
                console.log({searchResults});
            })
            .catch((error) => {
                // Gérer les erreurs ici
                console.error(error);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }, [projectId, currentPage, pageSize]);


    const resetData = () => {
        setPosts([])
        setPostsFromFacebook([])
        setPostsFromInstagram([])
        setPostsFromTwitter([])
        setPostsFromTiktok([])
        setPostsFromYoutube([])
        setPostsFromGoogle([])
    }

    const buildItemFromYoutubeApiResult = async (data: IDataYoutubeApiResponse[]) => {
        if (isEmpty(data)) return [];

        return data.map(result => {
            const user = {} as IUser
            user.name = result.data.channelName;
            user.profileUrl = result.data.channelUrl;
            user.profilePic = result.data.channelUrl;

            const item = {} as ISocialMediaItemProps;
            // item.user = null

            item.analyse = result.analyse

            item.id = result._id
            item.url = result.data.url
            item.commentsCount = result.data.commentsCount
            item.likesCount = result.data.likes
            item.sharesCount = 0
            item.date = result.data.date
            item.content = result.data.text
            item.socialNetwork = "youtube"
            return item;
        })
    }

    const goToNewProject = () => {
        navigate("/projects/add")
    }


    const kOnChange = async (socialMedia: ISocialMedia) => {
        const index = selectedSources.findIndex(selectedSource => selectedSource.id == socialMedia.id)

        if (index !== -1) {
            setSelectedSources((prevSelectedSources) => [
                ...prevSelectedSources.slice(0, index),
                socialMedia,
                ...prevSelectedSources.slice(index + 1),
            ]);
        } else {
            setSelectedSources((prevSelectedSources) => [...prevSelectedSources, socialMedia]);
        }
    }

    const onFilter = async () => {

        // const motEncode = encodeURIComponent(project?.name ?? "");
        const motEncode = project?.name ?? "";

        setIsSubmitting(true)

        resetData()

        setIsSubmitting(false)
    }

    const goToDashboard = () => {
        navigate("/dashboard")
    }

    const filterTransactions = async (props: IDateRangeProps | undefined) => {

        let startDate;
        let endDate;
        if (props) {
            startDate = props.startDate
            endDate = props.endDate
        }

        setFromDate(startDate)
        setToDate(endDate)

    }

    return (
        <div className="container-fluid p-0">


            <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                    <h3>Resultat pour {project?.name}</h3>
                </div>

                <div className="col-auto ms-auto text-end mt-n1">
                    <a onClick={goToDashboard} className="btn btn-light bg-white me-2">Liste des projets</a>
                    <a onClick={goToNewProject} className="btn btn-primary">Nouveau projet</a>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="tab">

                        {renderTabList(activeTab, handleTabChange)}
                        <div className="tab-content" style={{backgroundColor: "#f3f7fa", padding: 0}}>
                            <div className={`tab-pane ${activeTab === 'tab-1' ? 'active show' : ''}`} role="tabpanel">
                                <div className="row">
                                    <div className="col-md-8">
                                        {
                                            isSubmitting
                                                ?
                                                <div className="col-12 my-7">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="card-title">Veuillez patientez pendant un
                                                                instant...</h5>
                                                            <h6 className="card-subtitle text-muted"><br/>
                                                                Nous collectons les informations puis les analysons en
                                                                temps réel.</h6>
                                                        </div>
                                                        <div className="card-body text-center">

                                                            <div className="mb-2">
                                                                <div className="spinner-border text-primary me-2"
                                                                     role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div>
                                                    <SocialMediaItem
                                                        handlePageSizeChange={handlePageSizeChange}
                                                        posts={filteredPosts.length > 0 ? filteredPosts : posts}
                                                        currentPage={currentPage}
                                                        pageSize={pageSize}
                                                        totalMentions={totalMentions}
                                                        onPageChange={handlePageChange}
                                                    />
                                                </div>
                                        }
                                    </div>
                                        <div className="col-md-4">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="">
                                                        <h5 className="card-title mb-2">Periode</h5>
                                                        <KDateRange filterFn={filterTransactions}/>
                                                    </div>
                                                    <hr/>
                                                    <div className="">
                                                        <h5 className="card-title mb-2">Sources</h5>
                                                        <SocialMediaShortStat data={postsFromFacebook}
                                                                              socialMedia={faceBook}
                                                                              onChange={kOnChange}/>

                                                        <SocialMediaShortStat data={postsFromInstagram}
                                                                              socialMedia={instagram}
                                                                              onChange={kOnChange}/>
                                                        <SocialMediaShortStat data={postsFromTwitter}
                                                                              socialMedia={twitter}
                                                                              onChange={kOnChange}/>
                                                        <SocialMediaShortStat data={postsFromTiktok}
                                                                              socialMedia={tiktok}
                                                                              onChange={kOnChange}/>
                                                        <SocialMediaShortStat data={postsFromGoogle}
                                                                              socialMedia={googleSearch}
                                                                              onChange={kOnChange}/>
                                                        <SocialMediaShortStat data={postsFromYoutube}
                                                                              socialMedia={youtube}
                                                                              onChange={kOnChange}/>
                                                    </div>
                                                    <div className="d-grid mt-5">
                                                        <a onClick={onFilter} className="btn btn-primary">Filtrer les
                                                            résultats</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Project;

/**
 * @type import("@mui/material".Sx.props)
 */
const styles = {
    searchForm: {
        p: 1,
        mt: 3,
        mb: 5,
        width: {
            xs: "70%",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: 0.5,
        borderRadius: "8px",
        borderColor: "#00d3e9",
        boxShadow: "0 0 21px rgb(0 211 233 / 35%)"
    },
    input: {backgroundColor: "rgb(249, 249, 249)", borderRadius: "8px"},
    errorLabel: {
        fontSize: "14px",
        color: "red",
    },
    label: {
        fontWeight: 500,
        fontSize: "13px",
        color: "#959595",
        marginBottom: "1%",
    },
    errors: {
        color: "red"
    },
    form: {display: "flex", flexDirection: "column", alignItems: "center"},
    formSection: {
        display: "flex",
        flexDirection: "column",
        ml: {
            md: 5,
            xs: 5,
        },
        mr: {
            md: 5,
            xs: 5,
        },
        mt: 5,
    },
    transactions: {
        logo: {
            width: "32px",
            height: "32px",
            borderRadius: "100%",
        },
        videoTitle: {
            fontSize: "0.8rem",
            width: 490,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
            },
        },
        videoDescription: {
            fontSize: "0.7rem",
            color: "neutral.normal",
            width: 490,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
        videoDetails: {
            ml: 2,
        },
        videoColumn: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        iconColumn: {
            display: "flex",
            alignItems: "center",
        },
        iconColumnText: {
            fontSize: "16px",
            fontWeight: "700",
        },
    },
    overview: {
        fontSize: "16px",
        fontWeight: "700",
    },
    pageTitle: {
        mb: 2,
    },
    columnsContainer: {
        display: "flex",
        width: "100%",
        // flexDirection: {xs: "row"},
        justifyContent: "space-between",
        borderRadius: "8px",
        // border: "1px solid #C7C7C7",
        padding: "10px 10px",
    },
    callToAction: {
        textTransform: "none",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: {md: "27px", xs: "20px"},
        display: "flex",
        alignItems: "center",
        minWidth: {
            xs: "120px",
            sm: "150px",
        },
        height: "48px",
        borderRadius: "8px",
        // background: "linear-gradient(95.08deg, rgba(172, 228, 248, 0.48) 0%, rgba(18, 137, 189, 0.48) 100%)",
        // "-webkit-background-clip": "text",
        // "-webkit-text-fill-color": "transparent",
        // backgroundClip: "text",
        // textFillColor: "transparent",
        p: "10px",
    },
    operationTopUp: {
        textTransform: "none",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "27px",
        display: "flex",
        alignItems: "center",
        minWidth: "150px",
        height: "48px",
        borderRadius: "8px",
        color: "#FFFFFF",
        width: "100%",
        p: "10px",
        background: "linear-gradient(to right, rgba(172, 228, 248, 0.48), rgba(18, 137, 189, 0.48))",
        boxShadow: "5px #000",
    },
    item: {
        height: "328px",
        minHeight: "328px",
        borderRadius: "8px",
        border: "1px solid #C7C7C7",
    },
    postAuthorSection: {
        display: "flex",
        alignItems: "center",
        my: 3,
    },
    postMeta: {
        mr: 1,
        fontSize: "0.8rem",
        color: "neutral.normal",
        textAlign: "start"
    },
    divider: {
        my: 2,
    },
    postStats: {
        // display: "grid",
        display: "flex",
        flexDirection: "column"
        // gridTemplateColumns: { md: "1fr", lg: "1fr 1fr" },
    },
    cardTitle: {
        mt: 2,
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "32px",
        display: "flex",
        alignItems: "center",
        color: "#001021",
    },
    amount: {
        color: "#000",
        fontWeight: 700,
        fontSize: "33px",
    },
    balanceSection: {
        display: "flex",
        alignItems: "center",
        color: "#000",
    },
    balanceLabelSection: {
        mr: 1,
        color: "#000",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "2rem",
        lineHeight: "32px",
        display: "flex",
        alignItems: "center",
    },
};
