import {styled} from "@mui/material/styles";
import {Button} from "@mui/material";

const RoundGradientButton = styled(Button)(() => {
    return {
        // padding: ".9em",
        margin: "auto",
        position: 'relative',
        border: '1px solid transparent',
        backgroundClip: 'padding-box',
        borderRadius: "8px",
        textTransform: 'capitalize',
        color: "linear-gradient(to right, rgba(172, 228, 248, 0.48), rgba(18, 137, 189, 0.48))",
        fontSize: '16px',
        fontWeight: 400,
        background: '#FFFFFF',
        '&:after': {
            position: 'absolute',
            top: -1,
            left: -1,
            right: -1,
            bottom: -1,
            background: "linear-gradient(to right, rgba(172, 228, 248, 0.48), rgba(18, 137, 189, 0.48))",
            content: '""',
            zIndex: -1,
            borderRadius: "8px",
        },
        '&:active': {
            background: "linear-gradient(to right, rgba(172, 228, 248, 0.48), rgba(18, 137, 189, 0.48))",
            color: '#000000'
        },
        '&:hover': {
            background: "linear-gradient(to right, rgba(172, 228, 248, 0.48), rgba(18, 137, 189, 0.48))",
            color: '#000000'
        },
    }
});

export default RoundGradientButton;
