import React, {useState} from "react";
import {Box, Button, CssBaseline, Grid, Typography,} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import toast from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import {userRepository} from "./repository/user.repository";
import RoundGradientButton from "../../components/RoundGradientButton";
import KTextField from "../../components/KTextField";

import {ThreeBounce} from "better-react-spinkit";
import {t} from "i18next";
import {isEmpty} from "../../shared/_utils/isEmpty";
import Spacing from "../../components/Spacing";
import emailRegex from "../../shared/_utils/_helpers/emailRegex";

const SignUp = () => {
    const yimboLogo = process.env.PUBLIC_URL + "/assets/yimba.png";
    const acceleratorLogo = process.env.PUBLIC_URL + "/assets/accelerator.png";
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: {errors, isValid},
    } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const goToSignIn = () => {
        navigate("/login", {replace: true});
    };

    const goToHome = () => {
        navigate("/");
    };

    const handleRegistrationForm: SubmitHandler = async (signUpData) => {

        if (isEmpty(signUpData.firstName) ||
            isEmpty(signUpData.lastName) ||
            isEmpty(signUpData.password) ||
            isEmpty(signUpData.email)
        ) {
            toast.error("les champs ne sont pas correctement remplis");
            return;
        }

        setIsSubmitting(true);

        const {data, error} = await userRepository.registration(
            signUpData.email,
            signUpData.firstName + ' ' + signUpData.lastName,
            signUpData.password,
        );

        setIsSubmitting(false);

        if (null != data) {
            if (data.inserted_id) {
                toast.success("Votre compte à été crée")
                return goToSignIn()
            } else {
                toast.error(t('errorOccurred'));
            }
        } else {
            toast.error(error);
        }
    };

    const registrationFormRender = () => (
        <Box
            component="form"
            mt={0}
            mb={10}
            noValidate
            onSubmit={handleSubmit(handleRegistrationForm)}
            sx={styles.form}
        >

            <Box mt={3}
                 sx={{
                     width: {xs: "90%", md: "80%"},
                 }}
            >

                <Box sx={{display: "flex", flexDirection: "column", alignItems: "start"}}>
                    <Typography
                        sx={{fontSize: "2.2rem", fontWeight: 700, mb: 2, mt: 5}}
                    >
                        {t('createAnYimbaAccount')}
                    </Typography>

                    <Typography sx={{mb: 5}}>{t('justOneMinute')}</Typography>
                </Box>

                <Box sx={{width: {xs: "100%", md: "100%"}}}>
                    {isSubmitting ? (
                        <div className="mt-5">
                            <ThreeBounce size={35} color="#797DF9"/>
                        </div>
                    ) : (
                        <>
                            <Box>
                                <Box>
                                    <Typography sx={styles.label}>{t("firstName")}</Typography>
                                    <KTextField
                                        validation={register("firstName", {
                                            required: t("requiredField"),
                                        })}
                                        placeholder="Doe"
                                        fullWidth
                                    />
                                    {errors.lastName &&
                                    <Typography sx={styles.errors}>{errors.lastName.message}</Typography>}
                                </Box>
                                <Spacing marginBottom={"30px"}/>
                                <Box>
                                    <Typography sx={styles.label}>{t("lastName")}</Typography>
                                    <KTextField
                                        validation={register("lastName", {
                                            required: t("requiredField"),
                                        })}
                                        placeholder="John"
                                        fullWidth
                                    />
                                    {errors.firstName &&
                                    <Typography sx={styles.errors}>{errors.firstName.message}</Typography>}
                                </Box>
                                <Spacing marginBottom={"30px"}/>
                                <Typography sx={styles.label}>Email</Typography>
                                <KTextField
                                    validation={register("email", {
                                        required: false,
                                        pattern: {
                                            value: emailRegex,
                                            message: t("invalidField"),
                                        },
                                    })}
                                    placeholder="Ex: johndoe@gmail.com"
                                    fullWidth
                                />
                                {errors.email && <Typography sx={styles.errors}>{errors.email.message}</Typography>}
                            </Box>
                            <Spacing marginBottom={"30px"}/>
                            <Box>
                                <Typography sx={styles.label}>Password</Typography>
                                <KTextField
                                    type={"password"}
                                    validation={register("password", {
                                        required: t('requiredField'),
                                    })}
                                    placeholder="*********"
                                    fullWidth
                                />
                                {errors.password && <Typography color={"red"}>{errors.password.message}</Typography>}
                            </Box>
                            <Spacing marginBottom={"30px"}/>

                            <Box mt={5}>
                                <RoundGradientButton
                                    disabled={!isValid || isSubmitting}
                                    type="submit"
                                    variant={"contained"}
                                    color={"inherit"}
                                    sx={{
                                        background: isSubmitting
                                            ? "linear-gradient(to right, #C471ED, #4A90ED)"
                                            : "",
                                    }}
                                    size="large"
                                    fullWidth
                                >
                                    {isSubmitting && (
                                        <>
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: "#FFFFFF",
                                                    marginRight: "5%",
                                                }}
                                            />
                                        </>
                                    )}
                                    <Typography sx={{color: isSubmitting ? "#FFFFFF" : "", textTransform: "revert"}}>
                                        {t("createAccount")}
                                    </Typography>
                                </RoundGradientButton>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );

    return (
        <Box>

            <ThemeProvider theme={defaultTheme}>
                <Grid container component="main" sx={{height: '100vh'}}>
                    <CssBaseline/>

                    <Grid
                        item
                        xs={false}
                        sm={6}
                        md={6}
                        display={{xs: "none", sm: "flex", backgroundColor: "#f5f7fb"}}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 10,
                                mr: 10,
                                mt: 5,
                                flex: 1,
                                justifyContent: "space-between"
                            }}
                        >
                            <Box>
                                <Box
                                    component="img"
                                    src={acceleratorLogo}
                                    sx={{
                                        cursor: "pointer",
                                        width: "50%"
                                    }}
                                    onClick={goToHome}
                                />
                                <Box
                                    component="img"
                                    src={yimboLogo}
                                    sx={{
                                        cursor: "pointer",
                                        width: "50%"
                                    }}
                                    onClick={goToHome}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography sx={{fontSize: "2.5rem", fontWeight: 700, mt: -20}}>
                                    {t("welcomeToYimba")}
                                </Typography>
                                <Spacing marginBottom={"10px"}/>
                            </Box>
                            <Box
                                sx={{
                                    alignSelf: "center",
                                    alignItems: "center",
                                    marginBottom: "1%"
                                }}
                            >
                                <Copyright/>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        component={Paper}
                        display={{backgroundColor: "#e2ebf9"}}
                    >
                        <Box sx={styles.formSection}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: {
                                        xs: "space-between",
                                        sm: "right"
                                    },
                                    alignItems: "center",
                                }}
                            >
                                <Box
                                    xs="false"
                                    component="img"
                                    src={yimboLogo}
                                    sx={{
                                        display: {
                                            sm: "none"
                                        },
                                        width: "30%",
                                        cursor: "pointer"
                                    }}
                                    onClick={goToHome}
                                />
                            </Box>

                            <Box
                                sx={{
                                    mt: 10,
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    margin: "auto",
                                }}
                            >
                                {registrationFormRender()}
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    margin: "auto"
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "1.1rem", fontWeight: 400, color: "#001021", display: {
                                            xs: "none",
                                            sm: "initial"
                                        },
                                    }}
                                >
                                    {t("alreadyToPayqin")}
                                </Typography>
                                <Button
                                    onClick={goToSignIn}
                                    variant={"text"}
                                    color={"inherit"}
                                    sx={{
                                        fontSize: "1.1rem",
                                        color: "#4b87df",
                                        textDecoration: 'underline',
                                        textTransform: "revert",
                                        ml: 3,
                                    }}
                                >
                                    {t("login")}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>


            </ThemeProvider>
        </Box>
    );
};

function Copyright(props: any) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="https://yimba.org/">
                Yimba
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const defaultTheme = createTheme();

export default SignUp;

/**
 * @type
    {
        import("@mui/material").SxProps
    }
 */
const styles = {
    errors: {
        color: "red"
    },
    form: {display: "flex", flexDirection: "column", alignItems: "center"},
    formSection: {
        display: "flex",
        flexDirection: "column",
        ml: {
            md: 5,
            xs: 5,
        },
        mr: {
            md: 5,
            xs: 5,
        },
        mt: 5,
    },
};
