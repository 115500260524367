import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { projectRepository } from '../repository/project.repository';
import { buildQuery } from '../../../shared/_utils/_helpers/queryGenerator';
import { ISourceOption } from '../../../shared/types/sources';
import Header from '../../../components/_header/Header';
import Footer from '../../../components/_footer/Footer';
import './index.css';
import {ISearchRequest} from "../../../shared/types/searchRequest";
import {ILanguage} from "../../../shared/types/languages";
import {default as ReactSelect} from "react-select/dist/declarations/src/stateManager";
import {Option} from "../../Settings/components/option";
import Select, {MultiValue} from "react-select";
import {ktoast} from "../../../shared/_utils/_helpers/ktoast";
import {isEmpty} from "../../../shared/_utils/isEmpty";


interface IAddProject {
    name: string;
    languages: string[];
    selectedSources: string[];
}

interface FormData {
    sources: string[];
}

const AddProject = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [progress, setProgress] = useState(33);
    const [sources, setSources] = useState<ISourceOption[]>([]);
    const [languageOptions, setLanguageOptions] = useState<{ label: string, value: string }[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadingSources, setIsLoadingSources] = useState(false);
    const [isLoadingLanguages, setIsLoadingLanguages] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<MultiValue<{ label: string, value: string }>>([]);
    const addProjectIllustration = process.env.PUBLIC_URL + "/assets/brand_sidebar_image.svg";

    const {
        register,
        control,
        setValue,
        handleSubmit,
        watch,
        getValues,
        formState: { errors, isValid },
        setError,
    } = useForm<IAddProject>({
        defaultValues: {
            selectedSources: [],
            languages: [],
        },
    });

    useEffect(() => {
        getSources();
        getLanguages();
    }, []);

    const handleCheckboxChange = (code: string) => {
        const currentValue = watch('selectedSources') || [];
        const newValue = currentValue.includes(code)
            ? currentValue.filter((item: string) => item !== code)
            : [...currentValue, code];

        setValue('selectedSources', newValue);
    };

    const getSources = async () => {
        setIsLoadingSources(true);
        let { data, error } = await projectRepository.getSources();
        setIsLoadingSources(false);
        if (data) {
            const sources = Object.values(data).filter(source => !source.hidden);
            setSources(sources);
        }
    };

    const getLanguages = async () => {
        setIsLoadingLanguages(true);
        let { data, error } = await projectRepository.getLanguages();
        setIsLoadingSources(false);

        if (data == null ) return ;

        const _languageOptions = Object.values(data).map(lang => ({
            label: lang.name,
            value: lang.code
        }));

        setLanguageOptions(_languageOptions)
    };

    const handleChange = (selected: MultiValue<{ label: string, value: string }>) => {
        setSelectedOptions(selected);
    };

    const nextStep = () => {
        if (step < 3) {
            setStep(step + 1);
            setProgress(progress + 33);
        }
    };

    const prevStep = () => {
        if (step > 1) {
            setStep(step - 1);
            setProgress(progress - 33);
        }
    };

    const onSubmit: SubmitHandler<IAddProject> = async search => {

        const languagesArray = selectedOptions.map(lang => lang.value)

        if (isEmpty(search.name) || search.selectedSources.length <= 0 || languagesArray.length <= 0) {
            ktoast.error(t('Veuillez remplir correctement le formulaire'));
            return ;
        }

        const query = buildQuery(search.name);
        const requestBody: ISearchRequest = {
            languages: languagesArray,
            name: search.name,
            query: {
                query_string: query,
                type: 'advanced',
            },
            sources: search.selectedSources,
        };

        setIsSubmitting(true);
        const { data, error } = await projectRepository.createProject(requestBody);
        setIsSubmitting(false);
        if (data) {
            ktoast.success(t("L'alerte a été crée... \nNous collectons les données"));
            navigate(`/projects/${data.inserted_id}`, { replace: true });
        }
    };

    return (
        <div className="wrapper">
            <div className="main">
                <Header />
                <div className="container">
                    <div className="row vh-100 justify-content-around">
                        <div className="col-sm-9 col-md-7 d-table h-100 mt-7">
                            <div className="d-table-cell">
                            {/*<div className="">*/}
                                <div className="text-start">
                                    <h1 className="h2">Saisissez le sujet que vous désirez surveiller</h1>
                                    <p className="lead">
                                        Il s'agit du sujet que vous souhaitez suivre pour être informer des avis des internautes.
                                    </p>
                                </div>
                                <div className="progress mb-4">
                                    <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100}>
                                        {progress}%
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="m-sm-3">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className={`step-container`}>
                                                    <div className={`step ${step >= 1 ? 'active' : ''}`}>
                                                        <div className="form-group">
                                                            <label htmlFor="name">Texte de recherche:</label>
                                                            <input
                                                                {...register('name', { required: true })}
                                                                className="form-control form-control-lg"
                                                                id="name"
                                                                placeholder="Entrez le texte de recherche"
                                                            />
                                                            {errors.name && <p className="text-danger">Ce champ est requis</p>}
                                                        </div>
                                                    </div>
                                                    <div className={`step ${step >= 2 ? 'active' : ''}`}>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="mb-3">
                                                                    <label htmlFor="languages">Choisissez une langue:</label>
                                                                    <label className="form-label" htmlFor="inputAddress">Opérations selectionnées</label>
                                                                    <Select
                                                                        id="languages"
                                                                        options={languageOptions}
                                                                        isMulti
                                                                        closeMenuOnSelect={false}
                                                                        hideSelectedOptions={false}
                                                                        components={{ Option }}
                                                                        onChange={handleChange}
                                                                        value={selectedOptions}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {errors.languages && <p className="text-danger">Ce champ est requis</p>}
                                                        </div>
                                                    </div>
                                                    <div className={`step ${step >= 3 ? 'active' : ''}`}>
                                                        <div className="form-group">
                                                            <label htmlFor="sources">Choisissez les sources:</label>
                                                            <div className="multi-select-container mt-3">
                                                                <div className="checkbox-container">
                                                                    {sources.map(option => (
                                                                        <div key={option.code} className="checkbox-item">
                                                                            <input
                                                                                type="checkbox"
                                                                                id={option.code}
                                                                                value={option.code}
                                                                                checked={watch('selectedSources')?.includes(option.code) || false}
                                                                                {...register('selectedSources', { required: true })}
                                                                                onChange={() => handleCheckboxChange(option.code)}
                                                                            />
                                                                            <label htmlFor={option.code}>{option.name}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                {errors.selectedSources && <p className="text-danger">Ce champ est requis</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between mt-4">
                                                    {step > 1 && <button type="button" className="btn btn-secondary" onClick={prevStep}>Précédent</button>}
                                                    <div className="ms-auto">
                                                        {step < 3 && <button type="button" className="btn btn-primary" onClick={nextStep}>Suivant</button>}
                                                        {step === 3 && <button type="submit" className="btn btn-success">Soumettre</button>}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-2 col-md-4 d-table h-100 offset-1">
                            <div className="d-table-cell m-0 align-middle">

                            <img className="img-fluid" src={addProjectIllustration}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default AddProject;
