import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Box, SelectChangeEvent,} from "@mui/material";
import {useTranslation} from "react-i18next";
import {default as ReactSelect} from "react-select";
import {Option} from "./components/option";
import toast from "react-hot-toast";
import {permissionRepository} from "../../shared/repositories/permissions.repository";
import {ktoast} from "../../shared/_utils/_helpers/ktoast";

interface PermissionLabelValue {
    value: string;
    label: string;
}

const Settings = () => {
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        setValue,
        reset
    } = useForm({
        defaultValues: {
            name: "",
            description: "",
            permissions: [],
        },
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingRoles, setLoadingRoles] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [roles, setRoles] = useState();
    const [selectedRole, setSelectedRole] = useState();

    const [permissions, setPermissions] = useState();

    useEffect(() => {
        const modal = document.getElementById('defaultModalSuccess');

        if (modal) {
            modal.addEventListener('hidden.bs.modal', () => {
                setSelectedRole(null);
                setSelectedOptions([]);
                reset();
            });
        }

        return () => {
            if (modal) {
                modal.removeEventListener('hidden.bs.modal', () => {
                    setSelectedRole(null);
                    setSelectedOptions([]);
                    reset();
                });
            }
        };
    }, []);

    const handleChange = (selected) => {
        setSelectedOptions(selected);
    };

    const {i18n} = useTranslation("translation");

    const getRoles = () => {
        setLoadingRoles(true)
        permissionRepository.getRoles()
            .then(({data, error}) => {
                if (null != data) {
                    console.log({data})
                    setRoles(data.items)
                }
            })
            .finally(() => setLoadingRoles(false))
    }

    useEffect(() => {
        let language = i18n.language;
        language = language.split("-")[0];
        setSelectedLanguage(language);
        getPermissions();
        getRoles();
    }, []);

    const getPermissions = () => {
        setLoading(true)
        permissionRepository.getPermissions()
            .then(({data, error}) => {
                if (null != data) {
                    console.log(data[0].permissions)
                    const permissionLabelValueArray = data[0].permissions.map(permission => ({
                        value: permission.code,
                        label: permission.desc.fr
                    }));

                    setPermissions(permissionLabelValueArray)
                } else {
                    if (error) {
                        toast.error(error)
                    }
                }
            })
            .finally(() => setLoading(false))
    }

    const detailsRoleFn = async (roleId) => {
        const role = roles.find((r) => r._id === roleId);
        setSelectedRole(role)
    }

    const submitForm = async (inputs) => {
        const selectedPermissions = selectedOptions.map((option) => option.value);
        const formData = {
            ...inputs,
            permissions: selectedPermissions,
        };

        setLoading(true)

        var msg, role;
        if (!selectedRole) {
            const {data, error} = await permissionRepository.createRole(inputs.name, inputs.description)

            if (null == data) {
                setLoading(false)
                return;
            }

        msg = "Role crée avec success"
            role = data
        } else {
            msg = "Le role a bien été modifié"
            role = selectedRole
        }


            const resp = await permissionRepository.assignPermissions(role._id, selectedPermissions)

                if (null == resp.data) {
                    return;
                }
        setLoading(false)
        ktoast.success(msg)

        setSelectedOptions([]);

        const modal = document.getElementById('defaultModalSuccess');
        const modalInstance = bootstrap.Modal.getInstance(modal);
        modalInstance.hide();
        await getRoles();
    };

    const editRoleFn = (roleId) => {
        const role = roles.find((r) => r._id === roleId);
        setSelectedRole(role);

        setValue("name", role.name);
        setValue("description", role.description);

        const rolePermissionCodes = role.permissions
            .flatMap((service) => service.permissions.map((p) => p.code));

        const userSelectedPermissions = permissions.filter((globalPermission) =>
            rolePermissionCodes.includes(globalPermission.value)
        );

        console.log({userSelectedPermissions})
        console.log({permissions})
        console.log(role.permissions)
        setSelectedOptions(userSelectedPermissions);
    }

    return (

        <div className="container-fluid p-0">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                    <li className="breadcrumb-item"><a href="#">Settings</a></li>
                    <li className="breadcrumb-item active">Permissions</li>
                </ol>
            </nav>

            <button type="button" className="btn btn-primary float-end mt-n1" data-bs-toggle="modal"
                    data-bs-target="#defaultModalSuccess">
                <i className="fas fa-plus"></i>  Ajouter un role
            </button>

            <div className="mb-3">
                <h1 className="h3 d-inline align-middle">Gestion des roles</h1>

            </div>


            <div className="card">
                <div className="card-header pb-0">
                    <div className="card-actions float-end">
                        <div className="dropdown position-relative">
                            <a href="#" data-bs-toggle="dropdown" data-bs-display="static">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-horizontal align-middle">
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="19" cy="12" r="1"></circle>
                                    <circle cx="5" cy="12" r="1"></circle>
                                </svg>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#">Action</a>
                                <a className="dropdown-item" href="#">Another action</a>
                                <a className="dropdown-item" href="#">Something else here</a>
                            </div>
                        </div>
                    </div>
                    <h5 className="card-title mb-0">Roles and Permissions</h5>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Role</th>
                                <th>Permissions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {roles && roles.length > 0 ? (
                                roles.map((role, index) => (
                                    <tr key={role._id} className={index % 2 === 0 ? "even" : "odd"}>
                                        <td>{index + 1}</td>
                                        <td>{role.name}</td>
                                        <td>
                                            {role.description}
                                        </td>
                                        <td>
                                            <a data-bs-toggle="modal" data-bs-target="#defaultModalPrimary" onClick={() => detailsRoleFn(role._id)} href="#" className="btn btn-primary btn-sm m-1">Details</a>
                                            <a data-bs-toggle="modal" data-bs-target="#defaultModalSuccess" onClick={() => editRoleFn(role._id)} href="#" className="btn btn-primary btn-sm">Modifier</a>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" style={{ textAlign: "center" }}>No roles found.</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Modal to add role */}
            <div className="modal fade" id="defaultModalSuccess" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{selectedRole ? "Modifier le rôle" : "Ajouter un nouveau rôle"}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                            </div>
                            <div className="modal-body m-3">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="name">Nom du rôle</label>
                                    <input
                                        {...register("name", { required: "Le nom du rôle est requis" })}
                                        type="text"
                                        className={`form-control ${errors.name ? "is-invalid" : ""}`}
                                        placeholder="Nom du rôle"
                                    />
                                    {errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="description">Description</label>
                                    <input
                                        {...register("description", { required: "La description est requise" })}
                                        type="text"
                                        className={`form-control ${errors.description ? "is-invalid" : ""}`}
                                        placeholder="Description du rôle"
                                    />
                                    {errors.description && <div className="invalid-feedback">{errors.description.message}</div>}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Permissions sélectionnées</label>
                                    <ReactSelect
                                        options={permissions}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        onChange={handleChange}
                                        value={selectedOptions}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                                <button type="submit" className="btn btn-success">Enregistrer</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {/* Modal to show role details */}
            <div
                className="modal fade"
                id="defaultModalPrimary"
                tabIndex="-1"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Role Details</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body m-3">

                            {selectedRole ? (
                                <div>
                                    <h5>{selectedRole.name}</h5>
                                    <p>{selectedRole.description}</p>
                                    <h6>Permissions:</h6>
                                    <ul>
                                        {selectedRole.permissions &&  selectedRole.permissions.length > 0 ? selectedRole.permissions.map(service => (
                                            <li key={service.service_info.name}>
                                                <strong>{service.service_info.title.fr}</strong>
                                                <ul>
                                                    {service.permissions.map(permission => (
                                                        <li key={permission.code}>{permission.description.fr}</li>
                                                    ))}
                                                </ul>
                                            </li>
                                        )) : <li>
                                            No permission granted
                                        </li>
                                        }
                                    </ul>
                                </div>
                            ) : (
                                <p>No role selected.</p>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;

/**
 * @type
    {
        import("@mui/material").SxProps
    }
 */
const styles = {
    form: {
        width: {
            sm: "70%",
            md: "60%",
            lg: "40%",
        },
        margin: "auto",
    },
    title: {
        fontSize: "1rem",
        fontWeight: "400",
        display: "flex",
        justifyContent: "center",
    },
    label: {
        fontWeight: 500,
        fontSize: "13px",
        color: "#959595",
        textTransform: "none",
    },
    container: {
        width: {
            sm: "70%",
            md: "60%",
            lg: "60%",
        },
        // margin: "auto",
    },
};
