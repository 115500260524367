import React from 'react';

interface ISpacingProps {
    marginTop?: string;
    marginBottom?: string;
    marginLeft?: string;
    marginRight?: string;
    children?: React.ReactNode;
}

const Spacing: React.FC<ISpacingProps> = ({
                                                         marginTop,
                                                         marginBottom,
                                                         marginLeft,
                                                         marginRight,
                                                         children,
                                                     }) => {
    const spacingPropsStyle: React.CSSProperties = {
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
    };

    return <div style={spacingPropsStyle}>{children}</div>;
};

export default Spacing;
