import React from 'react';
import {useAuthUserContext} from '../shared/context/AuthUserContext';
import {hasPermission} from "../shared/_utils/_helpers/hasPermission";

interface ProtectedComponentProps {
    permissionsRequired: string[];
    children: React.ReactNode;
}

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({ permissionsRequired, children }) => {
    const { user } = useAuthUserContext();

    const hasRequiredPermissions = permissionsRequired.every(code => hasPermission(user, code));

    return hasRequiredPermissions ? <>{children}</> : <></>;
};

export default ProtectedComponent;
