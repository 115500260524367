import { Outlet, Navigate } from "react-router-dom";
import {useAuthUserContext} from "../shared/context/AuthUserContext";
import {accountService} from "../shared/services";

export default function AuthLayout() {
  const {user} = useAuthUserContext();

  return (
    <>
      {accountService.isLogged() ? (
        <Navigate to="/dashboard" />
      ) : (
        <>
          <div className="">
            <Outlet />
          </div>
        </>
      )}
    </>
  );
}
